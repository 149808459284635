import React, { Component } from "react";
//import from libraries
import { Row, Col, Card, Button, InputGroup, Input } from "reactstrap";
import { Grid } from "@material-ui/core";
import Select, { components } from "react-select";
import refreshIcon from "./assets/refresh-cw.png";
import userIcon from "./assets/user.png";
import UserIconDark from "./assets/userDark.png";
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      userLangs: undefined,
    };
  }

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  render() {
    darkMode = this.props?.mode;
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
        zIndex: 99999,
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div
            className="quickStylesBar reportQuickStyleBar"
            style={{
              width: "105vw",
            }}
          >
            <Row className="vehiclAnalysisRow">
              {/* <Col xs={0} md={4} lg={2}></Col> */}
              <Col xs={12} md={12} lg={12} className="">
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={1}
                    className="GridNone"
                  ></Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <div className="input-border ">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="routeCode"
                          value={this.props.filterNames?.routeCode}
                          onChange={(e) =>
                            this.props.handleChangeFilters({
                              name: "routeCode",
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Search By Route Code"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className=""
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <div className="input-border ">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="name"
                          value={this.props.filterNames?.name}
                          onChange={(e) =>
                            this.props.handleChangeFilters({
                              name: "name",
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Search By Name"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className=""
                            src={darkMode ? UserIconDark : userIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Select
                      className="hopes-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select Source"
                      // isMulti
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      isSearchable
                      styles={customStyles1}
                      // width="150px"
                      value={this.props.filterNames?.sourceHop}
                      options={this.props.hopOptions}
                      onChange={(e) =>
                        this.props.handleChangeFilters({
                          name: "sourceHop",
                          value: e,
                        })
                      }
                      onInputChange={(e) => {
                        this.props.filtersHops(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Select
                      className="hopes-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select Destination"
                      // isMulti
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      isSearchable
                      styles={customStyles1}
                      // width="150px"
                      value={this.props.filterNames?.destinationHop || ""}
                      options={this.props.hopOptions}
                      onChange={(e) =>
                        this.props.handleChangeFilters({
                          name: "destinationHop",
                          value: e,
                        })
                      }
                      onInputChange={(e) => {
                        this.props.filtersHops(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Select
                      className="hopes-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select ViaHop"
                      isMulti
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      isSearchable
                      styles={customStyles1}
                      // width="150px"
                      value={this.props.filterNames?.viaHops || ""}
                      options={this.props.hopOptions}
                      onChange={(e) =>
                        this.props.handleChangeFilters({
                          name: "viaHops",
                          value: e,
                        })
                      }
                      onInputChange={(e) => {
                        this.props.filtersHops(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={1}>
                    <Button
                      onClick={() => {
                        this.props.reset();
                      }}
                      className="reset"
                      style={{
                        float: "right",
                      }}
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
