import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getApi } from "../../helpers/auth_helper";
import { types } from "./actionTypes";

function* workfetchRoutes({ offset, source, destination, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/route/routesViaBySourceDest?offset=` +
      offset +
      `&limit=` +
      process.env.REACT_APP_PERPAGE +
      `&source=` +
      source +
      `&destination=` +
      destination;

    const response = yield call(getApi, uri);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ROUTES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}

function* workfetchRoutesForMasters({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let source = filters?.source ? filters?.source : "";
  let destination = filters?.destination ? filters?.destination : "";
  let viaHops = filters?.viaHops ? filters?.viaHops : "";
  let routeCode = filters?.routeCode ? filters?.routeCode : "";
  let name = filters?.name ? filters?.name : "";
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/route/orclRoutes?offset=` +
      offset +
      `&limit=` +
      process.env.REACT_APP_PERPAGE +
      `&source=` +
      source +
      `&destination=` +
      destination +
      "&routeCode=" +
      routeCode +
      "&name=" +
      name +
      "&viaHops=" +
      viaHops;

    // const uri =
    //   process.env.REACT_APP_SERVERURL +
    //   "route/orclRoutes?source=60cdb8e81f89dc258ed774b9&destination=60cdb8e81f89dc258ed774db&routeCode=1&name=381&viaHops=60cdb8e81f89dc258ed774eb,60cdb8e81f89dc258ed774ba&legalInvoiceNo=1";
    const response = yield call(getApi, uri);
    callback(response);
    localStorage.setItem(
      "RoutesData",
      response ? JSON.stringify(response) : null
    );

    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_ROUTES_FOR_MASTER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}
export function* watchFetchRoutes() {
  yield takeEvery(types.FETCH_ROUTES, workfetchRoutes);
}
export function* watchFetchRoutesForMaster() {
  yield takeEvery(types.FETCH_ROUTES_FOR_MASTER, workfetchRoutesForMasters);
}
function* RouteSaga() {
  yield all([fork(watchFetchRoutes)]);
  yield all([fork(watchFetchRoutesForMaster)]);
}
export default RouteSaga;
