import React, { Component } from "react";

import { Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import { fetchVehicles, fetchVehiclesExcel } from "../../store/vehicle/actions";
import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
import Pagination from "@material-ui/lab/Pagination";
import { getAccessForEntity } from "../../pages/policies";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import Popup from "../../components/Common/Popup";
import VehicleForm from "../../pages/Forms/VehicleForm";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
// var limit = process.env.REACT_APP_PERPAGE;
var darkTheme;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataLoading: false,
      form: {
        VehicleOwner: "",

        VehicleRegistrationNumber: "",
        type: "",
      },
      breadcrumbItems: [
        { title: "Vehicle", link: "#" },
        { title: "Vehicle Details", link: "#" },
      ],
      name: "",
      regNo: "",
      setOpenPopup: false,
      // disabled: true
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);

        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
    // this.handleInputDebounceName = debounce(this.getOwnerNameFunc, 1000);
    // this.handleInputDebounceRegNo = debounce(this.getRegNoFunc, 1500);
  }

  componentDidMount = () => {
    this.getAllData();
    this.fetchVehicleType();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  fetchVehicleType = (data) => {
    data = data || {};

    // console.log("func v type: ");
    this.props.fetchVehicleTypesList(offset, () => {});
  };
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getData();
    }
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  getAllData = () => {
    this.setState({
      dataLoading: true,
    });
    let offset = 0;
    // let limit = 10;
    let q;
    this.props.fetchVehicles(
      offset,
      { q: q, ownerName: this.state.name, regNo: this.state.regNo },
      (res) => {
        this.setState({
          dataLoading: false,
        });
      }
    );
  };

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };
  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    offset = 0;
    this.setState({ loading: true });

    this.getAllData();
  };
  filterList = () => {
    this.getData();
  };
  handleSearchName = (e, data) => {
    this.setState({ name: e?.target?.value }, () => {});
  };
  handleSearchVehicle = (e) => {
    this.setState({ regNo: e.target.value }, () => {});
  };
  getData = () => {
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    let fetchValue = this.state.form?.type?.value?.id;
    offset = 0;
    this.setState({
      dataLoading: true,
    });
    this.props.fetchVehicles(
      offset,
      {
        q: "",
        vehicleTypeId: fetchValue,
        ownerName: ownerName,
        regNo: regNo,
      },
      (res) => {
        this.setState({
          dataLoading: false,
        });
      }
    );
    // this.setState({disabled:false})
  };
  reset = (e) => {
    this.setState(
      {
        name: "",
        regNo: "",
        form: { type: "" },
        // disabled: true,
      },
      () => {
        this.getAllData();
      }
    );
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;

    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    let fetchValue = this.state.form?.type?.value?.id;
    this.setState({ dataLoading: true });
    this.props.fetchVehicles(
      offset,
      {
        q: "",
        vehicleTypeId: fetchValue,
        ownerName: ownerName,
        regNo: regNo,
      },
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };

  handleSubmitChange = (e) => {
    // console.log("handleSubmitChange", typeof e.target.value, e);
    const name = e.target.name;
    let value = e.target.value;
    let formData = this.state.form;
    formData[`${name}`] = value;

    this.setState({ form: formData }, () => {});
    let fetchValue = this.state.form?.type?.value?.id;
    // console.log(fetchValue, "fetch");
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    this.setState({ dataLoading: false });
    this.props.fetchVehicles(
      0,
      {
        q: "",
        vehicleTypeId: fetchValue,
        ownerName: ownerName,
        regNo: regNo,
      },
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };

  addVehicle = () => {
    this.setState({ setOpenPopup: true });
  };

  exportExcelData = () => {
    let ownerName = this.state.name;
    let regNo = this.state.regNo;
    let fetchValue = this.state.form?.type?.value?.id;
    offset = 0;
    this.setState({
      dataLoading: true,
    });
    // Call the action function using await to ensure proper execution
    this.props.fetchVehiclesExcel(
      offset,
      {
        q: "",
        vehicleTypeId: fetchValue,
        ownerName: ownerName,
        regNo: regNo,
      },
      (res) => {
        this.setState({ dataLoading: false }, () => {});
        // this.setState({ isExportingExcel: false });
        // let exdata=[]
        let exportData = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.map((ele) => {
            // console.log("ele", ele);
            exportData.push({
              "Vehicle Owner": ele?.ownerName ? ele?.ownerName : "N/A",
              "Reg. No": ele?.registration?.number
                ? ele?.registration?.number
                : "N/A",
              "Transporter": ele?.transporter?.name
                ? ele?.transporter?.name
                : "N/A",
              "Vehicle Type": ele?.type?.label ? ele?.type?.label : "N/A",
            });
          });
        }
        // console.log("export Data", exportData);
        if (exportData?.length > 0) {
          exportToCSV([...exportData], "Vehicles");
        } else {
          exportData.push({
            "Vehicle Owner": "",
            "Reg. No": "",
            "Transporter": "",
            "Vehicle Type": "",
          });
          exportToCSV([...exportData], "Vehicles");
        }
      }
    );
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const localStorageData = JSON.parse(
      localStorage.getItem("vehiclesListData")
    );
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;

    const { vehicles } = this.props;
    // const { name, regNo, disabled, form } = this.state;
    const vehicleList = navigator.onLine
      ? vehicles?.vehicles?.data?.docs
      : localStorageData?.data?.docs;

    let rows = [];
    let type = [];
    let data = this.props.vehicleType?.data?.docs;
    if (Array.isArray(data)) {
      if (data.length) {
        data.forEach((ele) => {
          type.push({
            label: ele.label + " - " + ele.vehicleTypeCode,
            value: {
              id: ele._id,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
      }
    }

    if (Array.isArray(vehicleList)) {
      offset = navigator.onLine
        ? this.props.vehicles?.vehicles.data?.offset
        : localStorageData?.data?.offset;
      totCnt = navigator.onLine
        ? this.props.vehicles?.vehicles?.data?.length
        : localStorageData?.data?.length;
      toVal = navigator.onLine
        ? this.props.vehicles?.vehicles?.data?.offset +
          this.props.vehicles?.vehicles?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      vehicleList.forEach((ele) => {
        rows.push({
          vehicleOwnerName: ele?.ownerName ? ele?.ownerName : "N/A",
          registrationNo: ele?.registration?.number
            ? ele?.registration?.number
            : "N/A",
          transporter: ele?.transporter?.name ? ele?.transporter?.name : "N/A",
          vehicleType: ele?.type?.label ? ele?.type?.label : "N/A",
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <React.Fragment>
        <div /* className="page-content" */>
          {getLocalStorage()}
          <QuickFilters
            toggleExcel={this.exportExcelData}
            addDriverFunc={this.addVehicle}
            reset={this.reset}
            handleDriverName={this.handleSearchName}
            name={this.state.name}
            handleDriverPhone={this.handleSearchVehicle}
            phoneNo={this.state.regNo}
            type={type}
            form={this.state.form}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.filterList}
            vehicleIcon={
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 1H1V8.22222H9.33333V1Z"
                  stroke="#2800FC"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                  stroke="#2800FC"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                  stroke="#2800FC"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                  stroke="#2800FC"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            mode={darkTheme}
          />

          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                // dataLength={drivers?.drivers?.data?.length}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                // dataLength={drivers?.drivers?.data?.length}
                dataLoading={this.state.dataLoading}
              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>

          <Popup
            title={userLang?.masters?.ADD_VEHICLE}
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
            mode={darkTheme}
          >
            <VehicleForm handleSubmit={this.handleSubmit} mode={darkTheme} />
          </Popup>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicles: state.Vehicle.vehicles,
  fetchId: state.Vehicle.vehicles.fetchId,
  policies: state.AccessPolicy.accessPolicies.policies,
  vehicleType: state.VehicleType.vehicleType.vehicleType,
});

export default connect(mapStateToProps, {
  fetchVehicles,
  fetchVehiclesExcel,
  fetchVehicleTypesList,
})(Vehicle);
