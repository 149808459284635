import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  deleteApi,
  getApi,
  // patchApi,
  putUploadApi,
  postApi,
} from "../../helpers/auth_helper";
import { types } from "./actionTypes";

function* workfetchVehicleTypesList({ offset, callback }) {
  try {
    // Try to call the API
    // console.log("here saga");
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicleType/vehicleTypes?offset=` +
      offset;
    const response = yield call(getApi, uri);
    // console.log(response);
    callback(response);
    // callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_VEHICLETYPELIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch posts.", error);
  }
}


function* workfetchVehicleTypefilters({ offset, filters, callback }) {

  let filterParameterString = "";

  if (filters.label !== "" && filters.label !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&label=` + filters.label
    );
  } else filterParameterString = filterParameterString.concat(`&label=`);

  if (filters.vehicleTypeCode !== "" && filters.vehicleTypeCode !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&vehicleTypeCode=` + filters.vehicleTypeCode
    );
  } else filterParameterString = filterParameterString.concat(`&vehicleTypeCode=`);

  if (filters.weight !== ""  && filters.weight !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&weight=` + filters.weight
    );
  } else filterParameterString = filterParameterString.concat(`&weight=`);

  if (filters.lengths !== ""  && filters.lengths !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&length=` + filters.lengths
    );
  } else filterParameterString = filterParameterString.concat(`&length=`);

  if (filters.width !== "" && filters.width !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&width=` + filters.width
    );
  } else filterParameterString = filterParameterString.concat(`&width=`);

  if (filters.height !== "" && filters.height !== undefined) {
    filterParameterString = filterParameterString.concat(`&height=` + filters.height);
  } else filterParameterString = filterParameterString.concat(`&height=`);
  
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicleType/vehicleTypes?offset=` +
      offset +
      `&limit=` +
      process.env.REACT_APP_PERPAGE +
      filterParameterString;
    const response = yield call(getApi, uri);
     //   Dispatch the action to the reducers
     yield put({
      type: types.SPEC_FILTER_VEHICLETYPE_SUCCESS,
      payload: response.data,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch users.", error);
  }
}

function* workfetchVehicleTypefiltersExcel({ offset, filters, callback }) {

  let filterParameterString = "";

  if (filters.label !== "" && filters.label !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&label=` + filters.label
    );
  } else filterParameterString = filterParameterString.concat(`&label=`);

  if (filters.vehicleTypeCode !== "" && filters.vehicleTypeCode !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&vehicleTypeCode=` + filters.vehicleTypeCode
    );
  } else filterParameterString = filterParameterString.concat(`&vehicleTypeCode=`);

  if (filters.weight !== ""  && filters.weight !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&weight=` + filters.weight
    );
  } else filterParameterString = filterParameterString.concat(`&weight=`);

  if (filters.lengths !== ""  && filters.lengths !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&length=` + filters.lengths
    );
  } else filterParameterString = filterParameterString.concat(`&length=`);

  if (filters.width !== "" && filters.width !== undefined) {
    filterParameterString = filterParameterString.concat(
      `&width=` + filters.width
    );
  } else filterParameterString = filterParameterString.concat(`&width=`);

  if (filters.height !== "" && filters.height !== undefined) {
    filterParameterString = filterParameterString.concat(`&height=` + filters.height);
  } else filterParameterString = filterParameterString.concat(`&height=`);
  
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicleType/vehicleTypes?offset=` +
      offset +
      `&limit=100000` +
      process.env.REACT_APP_PERPAGE +
      filterParameterString;
    const response = yield call(getApi, uri);
     //   Dispatch the action to the reducers
     yield put({
      type: types.SPEC_FILTER_VEHICLETYPE_SUCCESS_EXCEL,
      payload: response.data,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch users.", error);
  }
}

function* workAddVehicleTypeSpec({ values , callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/create`;
    const response = yield call(postApi, uri, values);
    //   Dispatch the action to the reducers
    yield put({
      type: types.SPEC_ADD_VEHICLETYPE_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }  
 }

function* workUpdateVehicleTypeSpec({Id, values , callback }) {
try {
  // Try to call the API
  const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/update/${Id}`;
  const response = yield call(postApi, uri, values);
  //   Dispatch the action to the reducers
  yield put({
    type: types.SPEC_UPDATE_VEHICLETYPE_SUCCESS,
    payload: response,
  });
  callback(response);
} catch (error) {
  // Act on the error
  console.log("Request failed! Could not fetch .", error);
}  
}

function* workDeleteVehicleTypeSpec({Id, callback }) {
  try {

    const uri = process.env.REACT_APP_SERVERURL + `/vehicleType/delete/` + Id;
    const response = yield call(deleteApi, uri);
    callback(response);
    //Dispatch the action to the reducers
    yield put({
      type: types.SPEC_DELETE_VEHICLETYPE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not delete vehicle.", error);
  } 
}
function* workFetchSignedUrlVehicleTypeSpec({callback }) {
  try {

    const uri = process.env.REACT_APP_SERVERURL + `/upload/vehicleType/vehicleTypeImage`;
    const response = yield call(getApi, uri);
    // console.log("response---------", response);
    yield put({
      type: types.SPEC_FETCHSIGNEDURL_VEHICLETYPE_SUCCESS,
      payload: response,
    });
    callback(response);
    } catch (error) {
      ////consolelog("Fetch signed URL failed.", error);
    }
}

function* workUploadVehicleType({ uri, data, headers }) {
  try {
    // Try to call the API
    const response = yield call(putUploadApi, uri, data, headers);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_VEHICLETYPE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log(error);
  }
}
  
export function* watchFetchVehicleTypesList() {
  // console.log("here saga...");

  yield takeEvery(types.FETCH_VEHICLETYPELIST, workfetchVehicleTypesList);
}


export function* watchFetchVehicleTypeFilter() {
  yield takeEvery(types.SPEC_FILTER_VEHICLETYPE, workfetchVehicleTypefilters);
}

export function* watchFetchVehicleTypeFilterExcel() {
  yield takeEvery(types.SPEC_FILTER_VEHICLETYPE_EXCEL, workfetchVehicleTypefiltersExcel);
}

export function* watchAddVehicleTypeSpec() {
  yield takeEvery(types.SPEC_ADD_VEHICLETYPE, workAddVehicleTypeSpec);
}

export function* watchUpdateVehicleTypeSpec() {
  yield takeEvery(types.SPEC_UPDATE_VEHICLETYPE, workUpdateVehicleTypeSpec);
}

export function* watchDeleteVehicleTypeSpec() {
  yield takeEvery(types.SPEC_DELETE_VEHICLETYPE, workDeleteVehicleTypeSpec);
}

export function* watchFetchSignedUrlVehicleTypeSpec() {
  yield takeEvery(types.SPEC_FETCHSIGNEDURL_VEHICLETYPE, workFetchSignedUrlVehicleTypeSpec);
}

export function* watchUploadVehicleType() {
  yield takeEvery(types.UPLOAD_VEHICLETYPE, workUploadVehicleType);
}

function* VehicleTypeSaga() {
  yield all([
    fork(watchFetchVehicleTypesList),
    fork(watchFetchVehicleTypeFilter),
    fork(watchFetchVehicleTypeFilterExcel),
    fork(watchAddVehicleTypeSpec),
    fork(watchUpdateVehicleTypeSpec),
    fork(watchDeleteVehicleTypeSpec),
    fork(watchFetchSignedUrlVehicleTypeSpec),
    fork(watchUploadVehicleType),
  ]);
}




export default VehicleTypeSaga;
