import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import jwt from "jwt-decode";
import { withRouter } from "react-router-dom";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { fetchRoleGrpPolicies } from "./store/accessPolicy/actions";
import { loadUser } from "./store/auth/login/actions";

// Import scss
import "./baxterTheme.scss";
import "./theme.scss";
import jwtDecode from "jwt-decode";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomVal: "",
    };
    this.getLayout = this.getLayout.bind(this);
  }

  componentDidMount() {
    // document.body.style.zoom = 0.8;
    const { policies } = this.props;

    // window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener("resize", this.resize);

    const isLogin = localStorage.getItem("authUser");

    // we are not using concept of policy anymore.
    // if (!policies) {
    //   console.log("here", isLogin);
    //   if (isLogin) {
    //     this.props.fetchRoleGrpPolicies((res) => {
    //       // console.log("policiesss",res)
    //     });
    //   }
    // }
    if (isLogin) {
      this.props.loadUser(JSON.parse(isLogin).user);
    }
  }
  componentDidUpdate() {
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    // console.log(window.innerWidth, "-- SCREEN SIZE");
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    // else if( window.innerWidth >= 1500 && window.innerWidth <= 1600 ){
    //   this.setState({ zoomVal: 0.84 }, () => {
    //     document.body.style.zoom = this.state.zoomVal;
    //   });
    // }
    else {
      this.setState({ zoomVal: 0.9 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  // loadGoogleMapsScript = () => {
  //   if (process.env.REACT_APP_CLIENT === "") {
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCUQ0gKhCkZCjPZtXcO5cpYH_wY8GO-j_A&libraries=places&callback=initMap`;
  //     script.async = true;
  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // };

  render() {
    let tokenVal = localStorage.getItem("token") || "";

    if (tokenVal) {
      let tokenDecode = jwtDecode(tokenVal);

      if (new Date() / 1000 >= tokenDecode?.exp) {
        localStorage.clear();
        window.localStorage.clear();
        this.props.history.push("/login");
      }
    }
    const Layout = this.getLayout();
    //const Layout = <h1>Hello i am here</h1>

    return (
      <>
        <React.Fragment>
          <Router>
            {/* {this.loadGoogleMapsScript()} */}
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </Router>
        </React.Fragment>
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    policies: state.AccessPolicy.accessPolicies.policies,
    user: state.Login.user,
  };
};

export default withRouter(
  connect(mapStateToProps, { fetchRoleGrpPolicies, loadUser })(App)
);
