import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import phoneIcon from "./assets/phone.png";
import phoneDark from "./assets/phoneDrk.png";
import AtIcon from "./assets/Group 3543.png";
import AtDicon from "./assets/emailIconDark.png";
import userIcon from "./assets/user.png";
import UserIconDark from "./assets/userDark.png";
import { InputGroup, Input } from "reactstrap";
import Select, { components } from "react-select";
import refreshIcon from "./assets/refresh-cw.png";
import exportDarkIcon from "./assets/external-linkDark.png";
import exportIcon from "./assets/external-link.png";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};

class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    this.state.userLangs = JSON.parse(
      window.localStorage.getItem("userLangDetail")
    );
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    const customStyles2 = {
      menu: ({ width, ...css }) => ({
        ...css,
        width: "100%",
        minWidth: "100%",
        zIndex: 99,
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      control: (style) => {
        return {
          ...style,
          height: "26px",
          width: this.props.selectedHopValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
          border: darkMode ? "1.5px solid #B7A9FF" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
          color: darkMode ? "#9d9d9d" : "",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar">
            {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
              <Row>
                <Col xs={0} md={2}></Col>
                <Col xs={12} md={1} className="mobileDisplay">
                  <div
                    style={{
                      right: "0px",
                      float: "right",
                      paddingRight: "0px",
                      marginRight: "-7px",
                    }}
                    className="d-inline-flex justify-content-end"
                  >
                    <div>
                      <Button
                        color=""
                        style={{
                          minWidth: "78%",
                          marginRight: "0px",
                        }}
                        className="mobile-reset reset "
                        onClick={() => this.props?.reset()}
                      >
                        <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img>
                      </Button>
                    </div>
                    <div
                      style={{
                        right: "0px",
                        float: "right",
                        paddingRight: "0px",
                        marginRight: "-7px",
                      }}
                      className="d-inline-flex justify-content-end"
                    >
                      <div className="tooltipIcon">
                        <Button
                          color=""
                          disabled={!navigator.onLine}
                          onClick={() => this.props.toggleExcel()}
                          className={`iconButtons ${darkMode ? "iconDarkButtons" : ""
                            }`}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? exportDarkIcon : exportIcon}
                            alt="exp"
                          />
                        </Button>
                        <span className="tooltipIcontext" style={{ left: "10%" }}>
                          &nbsp;
                          {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                            "Download Excel"}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="parentCode"
                        placeholder="Search By Parent Code..."
                        aria-label="Search"
                        value={this.props?.parentCode}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        onChange={(e) =>
                          this.props?.filterAssigner(e, "parentCode")
                        }
                      />
                      <span className="pt-2 pb-2 input-line lineDark pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className=""
                          src={darkMode ? UserIconDark : userIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col sm={12} md={2}>
                  <div className="mt-2">
                    <Select
                      className="roles-react-selector textRole"
                      classNamePrefix="react-select"
                      placeholder="Select Roles..."
                      isMulti={false}
                      // autoFocus={true}
                      isSearchable
                      styles={customStyles2}
                      // width="150px"
                      value={this.props.selectedRoleValue}
                      options={this.props.roleOptions}
                      onChange={this.props.onChangeRole}
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.11829 13V11.968C9.11829 11.4206 8.90084 10.8957 8.51377 10.5086C8.12671 10.1215 7.60173 9.90408 7.05434 9.90408H2.92723C2.37983 9.90408 1.85486 10.1215 1.4678 10.5086C1.08073 10.8957 0.863281 11.4206 0.863281 11.968V13"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.82897 7.61317C5.90596 7.61317 6.77903 6.7401 6.77903 5.66311C6.77903 4.58612 5.90596 3.71304 4.82897 3.71304C3.75198 3.71304 2.87891 4.58612 2.87891 5.66311C2.87891 6.7401 3.75198 7.61317 4.82897 7.61317Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.1398 10.6599V9.62791C16.1398 9.08052 15.9223 8.55555 15.5353 8.16848C15.1482 7.78142 14.6232 7.56396 14.0758 7.56396H9.94871C9.40132 7.56396 8.87635 7.78142 8.48928 8.16848C8.10222 8.55555 7.88477 9.08052 7.88477 9.62791"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.8505 5.27302C12.9274 5.27302 13.8005 4.39995 13.8005 3.32296C13.8005 2.24597 12.9274 1.37289 11.8505 1.37289C10.7735 1.37289 9.90039 2.24597 9.90039 3.32296C9.90039 4.39995 10.7735 5.27302 11.8505 5.27302Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                    // onKeyDown={(event) => this.props?.enterCheck(event)}
                    />
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder="Search By Email..."
                        aria-label="Search"
                        value={this.props?.emailFilter}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        onChange={(e) =>
                          this.props?.filterAssigner(e, "emailFilter")
                        }
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? AtDicon : AtIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="QuickParent">
                    <button
                      color=""
                      className="uploadButton"
                      onClick={() => this.props.openModal()}
                    >
                      Upload Excel
                    </button>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pr-2 desktopDisplay">
                  <div style={{ width: "70%", float: "left" }}>
                    <Button
                      color=""
                      onClick={() => this.props?.reset()}
                      className="reset"
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </div>
                  <div style={{ width: "70%", float: "left" }}>
                    <div className="tooltipIcon">
                      <Button
                        color=""
                        disabled={!navigator.onLine}
                        onClick={() => this.props.toggleExcel()}
                        className={`iconButtons ${darkMode ? "iconDarkButtons" : ""
                          }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? exportDarkIcon : exportIcon}
                          alt="exp"
                        />
                      </Button>
                      <span className="tooltipIcontext" style={{ left: "10%" }}>
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className="ml-4">
                {/* <Col xs={0} md={1}></Col> */}
                <Col xs={12} md={1} className="mobileDisplay">
                  <div
                    style={{
                      right: "0px",
                      float: "right",
                      paddingRight: "0px",
                      marginRight: "-7px",
                    }}
                    className="d-inline-flex justify-content-end"
                  >
                    <div>
                      <Button
                        color=""
                        style={{
                          minWidth: "78%",
                          marginRight: "0px",
                        }}
                        className="mobile-reset reset "
                        onClick={() => this.props?.reset()}
                      >
                        <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img>
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{
                      right: "0px",
                      float: "right",
                      paddingRight: "0px",
                      marginRight: "-7px",
                    }}
                    className="d-inline-flex justify-content-end"
                  >
                    <div className="tooltipIcon">
                      <Button
                        color=""
                        disabled={!navigator.onLine}
                        onClick={() => this.props.toggleExcel()}
                        className={`iconButtons ${darkMode ? "iconDarkButtons" : ""
                          }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? exportDarkIcon : exportIcon}
                          alt="exp"
                        />
                      </Button>
                      <span className="tooltipIcontext" style={{ left: "10%" }}>
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={2}>
                  <div className="mt-2">
                    <Select
                      className="roles-react-selector textRole"
                      classNamePrefix="react-select"
                      placeholder="Select Roles..."
                      isMulti={false}
                      // autoFocus={true}
                      isSearchable
                      styles={customStyles2}
                      // width="150px"
                      value={this.props.selectedRoleValue}
                      options={this.props.roleOptions}
                      onChange={this.props.onChangeRole}
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.11829 13V11.968C9.11829 11.4206 8.90084 10.8957 8.51377 10.5086C8.12671 10.1215 7.60173 9.90408 7.05434 9.90408H2.92723C2.37983 9.90408 1.85486 10.1215 1.4678 10.5086C1.08073 10.8957 0.863281 11.4206 0.863281 11.968V13"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.82897 7.61317C5.90596 7.61317 6.77903 6.7401 6.77903 5.66311C6.77903 4.58612 5.90596 3.71304 4.82897 3.71304C3.75198 3.71304 2.87891 4.58612 2.87891 5.66311C2.87891 6.7401 3.75198 7.61317 4.82897 7.61317Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.1398 10.6599V9.62791C16.1398 9.08052 15.9223 8.55555 15.5353 8.16848C15.1482 7.78142 14.6232 7.56396 14.0758 7.56396H9.94871C9.40132 7.56396 8.87635 7.78142 8.48928 8.16848C8.10222 8.55555 7.88477 9.08052 7.88477 9.62791"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.8505 5.27302C12.9274 5.27302 13.8005 4.39995 13.8005 3.32296C13.8005 2.24597 12.9274 1.37289 11.8505 1.37289C10.7735 1.37289 9.90039 2.24597 9.90039 3.32296C9.90039 4.39995 10.7735 5.27302 11.8505 5.27302Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                    // onKeyDown={(event) => this.props?.enterCheck(event)}
                    />
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="mt-2">
                    <Select
                      className="hopes-react_selector"
                      classNamePrefix="react-select"
                      placeholder="Select Hops..."
                      isMulti={false}
                      // autoFocus={true}
                      isSearchable
                      styles={customStyles2}
                      // width="150px"
                      value={this.props.selectedHopValues}
                      options={this.props.hopOptions}
                      onChange={this.props.onChangeHop}
                      components={{ DropdownIndicator }}
                      selectProps={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M12.455 6.7275C12.455 11.1825 6.727 15.0005 6.727 15.0005C6.727 15.0005 1 11.1825 1 6.7275C1 5.20847 1.60343 3.75166 2.67755 2.67755C3.75166 1.60343 5.20847 1 6.7275 1C8.24653 1 9.70334 1.60343 10.7775 2.67755C11.8516 3.75166 12.455 5.20847 12.455 6.7275V6.7275Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 9.00049C8.10457 9.00049 9 8.10506 9 7.00049C9 5.89592 8.10457 5.00049 7 5.00049C5.89543 5.00049 5 5.89592 5 7.00049C5 8.10506 5.89543 9.00049 7 9.00049Z"
                            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      onInputChange={(e) => this.props?.filtersHops(e)}
                    // onKeyDown={(event) => this.props?.enterCheck(event)}
                    />
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="Name"
                        placeholder="Search By Name..."
                        aria-label="Search"
                        value={this.props?.nameFilter}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        onChange={(e) =>
                          this.props?.filterAssigner(e, "nameFilter")
                        }
                      />
                      <span className="pt-2 pb-2 input-line lineDark pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className=""
                          src={darkMode ? UserIconDark : userIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder="Search By Email..."
                        aria-label="Search"
                        value={this.props?.emailFilter}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        onChange={(e) =>
                          this.props?.filterAssigner(e, "emailFilter")
                        }
                      />
                      <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? AtDicon : AtIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="input-border mt-2">
                    <InputGroup>
                      <Input
                        className="form-control "
                        type="text"
                        name="phone"
                        placeholder="Search By Phone No..."
                        aria-label="Search"
                        value={this.props?.phoneFilter}
                        onKeyDown={(event) => this.props?.enterCheck(event)}
                        onChange={(e) =>
                          this.props?.filterAssigner(e, "phoneFilter")
                        }
                      />
                      <span className="pt-2 pb-2 input-line lineDark pl-1 ml-1"></span>
                      <span
                        className="span-img"
                        onClick={() => this.props?.filterList()}
                      >
                        <img
                          className="pl-1"
                          src={darkMode ? phoneDark : phoneIcon}
                          alt=""
                        ></img>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pr-2 desktopDisplay">
                  <div style={{ width: "70%", float: "left"}}>
                    <Button
                      onClick={() => this.props?.reset()}
                      className="reset"
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </div>
                  <div style={{ width: "70%", float: "left" }}>
                    <div className="tooltipIcon">
                      <Button
                        color=""
                        disabled={!navigator.onLine}
                        onClick={() => this.props.toggleExcel()}
                        className={`iconButtons ${darkMode ? "iconDarkButtons" : ""
                          }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? exportDarkIcon : exportIcon}
                          alt="exp"
                        />
                      </Button>
                      <span className="tooltipIcontext" style={{ left: "10%" }}>
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  {/* <div style={{ width: "5%", float: "left" }}>
                  <Button
                    className="iconButtons"
                    onClick={() => this.props?.onClickAddUser()}
                  >
                    <img style={{ margin: "-4px" }} src={plusIcon} alt="exp" />
                  </Button>
                </div> */}
                </Col>
              </Row>
            )}
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
