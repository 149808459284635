// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel,
} from "../../helpers/auth_helper";

// import axios from 'axios';
// const instance = axios.create();
function* workUploadDriver({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + "/upload/csv-driver-upload/single";
    const response = yield call(uploadExcel, uri, values);
    // console.log(response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.UPLOAD_DEALERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workfetchDrivers({ offset, name, phone, filters ,callback }) {
  try {
    // console.log("filters test", filters);
    // Try to call the API
    let uri;
    let uriAdditionalParameters = filters?.transporterId
      ? "&transporterId=" + filters?.transporterId
      : "";
    const limit = filters?.noLimit ? 0 : process.env.REACT_APP_PERPAGE;
    if (filters === null || filters === undefined) {
      uri =
        process.env.REACT_APP_SERVERURL +
        `/driver/drivers?offset=` +
        offset +
        `&limit=` +
        process.env.REACT_APP_PERPAGE +
        `&name=` +
        name +
        `&phone=` +
        phone;

      // console.log(uri, "uri");
    } else {
      if (Object.keys(filters)[0] === "name") {
        uri =
          process.env.REACT_APP_SERVERURL +
          `/driver/drivers?offset=` +
          offset +
          `&limit=` +
          limit +
          `&name=` +
          filters.name +
          uriAdditionalParameters;
      } else if (Object.keys(filters)[0] === "phone") {
        uri =
          process.env.REACT_APP_SERVERURL +
          `/driver/drivers?offset=` +
          offset +
          `&limit=` +
          limit +
          `&phone=` +
          filters.phone +
          uriAdditionalParameters;
      } else {
        uri =
          process.env.REACT_APP_SERVERURL +
          `/driver/drivers?offset=` +
          offset +
          `&limit=` +
          limit +
          uriAdditionalParameters;
      }
    }
    const response = yield call(getApi, uri);
    callback(response)
    yield put({
      type: types.FETCH_DRIVERS_SUCCESS,
      payload: response,
    });
    localStorage.setItem("driversListData", response ? JSON.stringify(response):null);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workfetchDriversExcel({ offset, name, phone, filters ,callback }) {
  try {
    // console.log("filters test", filters);
    // Try to call the API
    console.log(">>>>>>>>>>>>IN SAGA")
    let uri;
    let uriAdditionalParameters = filters?.transporterId
      ? "&transporterId=" + filters?.transporterId
      : "";
    const limit = filters?.noLimit ? 100000 : process.env.REACT_APP_PERPAGE;
    if (filters === null || filters === undefined) {
      uri =
        process.env.REACT_APP_SERVERURL +
        `/driver/drivers?offset=` +
        offset +
        `&limit=100000` +
        process.env.REACT_APP_PERPAGE +
        `&name=` +
        name +
        `&phone=` +
        phone;

      // console.log(uri, "uri");
    } else {
      if (Object.keys(filters)[0] === "name") {
        uri =
          process.env.REACT_APP_SERVERURL +
          `/driver/drivers?offset=` +
          offset +
          `&limit=` +
          limit +
          `&name=` +
          filters.name +
          uriAdditionalParameters;
      } else if (Object.keys(filters)[0] === "phone") {
        uri =
          process.env.REACT_APP_SERVERURL +
          `/driver/drivers?offset=` +
          offset +
          `&limit=` +
          limit +
          `&phone=` +
          filters.phone +
          uriAdditionalParameters;
      } else {
        uri =
          process.env.REACT_APP_SERVERURL +
          `/driver/drivers?offset=` +
          offset +
          `&limit=` +
          limit +
          uriAdditionalParameters;
      }
    }
    const response = yield call(getApi, uri);
    callback(response)
    yield put({
      type: types.FETCH_DRIVERS_SUCCESS_EXCEL,
      payload: response,
    });
    // localStorage.setItem("driversListData", response ? JSON.stringify(response):null);
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workfetchDriversfilter({ offset, filters, callback }) {
  try {
    // Try to call the API
    // console.log("here filters");
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/driver/drivers?offset=` +
      offset +
      `&filters=` +
      filters;
    const response = yield call(getApi, uri);
    callback(response);
    //console.log('FETCH_DRIVERS',response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_FILTER_DRIVERS_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workfetchAvailDrivers({ callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/driver/filterDrivers?status=AVAILABLE`;
    const response = yield call(getApi, uri);
    callback(response);
    //console.log('FETCH_DRIVERS',response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_AVAIL_DRIVERS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workCreateDriver({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/driver/create`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_DRIVER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workEditDriverById({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/driver/update/` + values.id;
    const response = yield call(patchApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.EDIT_DRIVER_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workDeleteDriver({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/driver/delete/` + id;
    const response = yield call(deleteApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.DELETE_DRIVER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
function* workFetchDriverById({ id, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/driver/driver/` + id;
    const response = yield call(getApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_DRIVER_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workSubscribeConsent({ phone, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/tracking/subscribe/${phone} `;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SUBSCRIBE_CONSENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}

function* workUnSubscribeConsent({ phone, callback }) {
  try {
    const uri =
      process.env.REACT_APP_SERVERURL + `/tracking/unsubscribe/${phone} `;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.UNSUBSCRIBE_CONSENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch driver.", error);
  }
}
// function* workfetchDriverConcentStatus({ driverId, callback }) {
//   try {
//     const uri =
//       process.env.REACT_APP_SERVERURL + `/driver/checkConsent/${driverId} `;
//     const response = yield call(getApi, uri);
//     callback(response);
//   } catch (error) {
//     // Act on the error
//     console.log("Request failed! Could not fetch driver.", error);
//   }
// }
// function* workfetchDriverAddConcent({ driverId, callback }) {
//   try {
//     const uri =
//       process.env.REACT_APP_SERVERURL + `/driver/updateConsent/${driverId} `;
//     const response = yield call(patchApi, uri);
//     callback(response);
//   } catch (error) {
//     // Act on the error
//     console.log("Request failed! Could not fetch driver.", error);
//   }
// }

export function* watchFetchDrivers() {
  yield takeEvery(types.FETCH_DRIVERS, workfetchDrivers);
}

export function* watchFetchDriversExcel() {
  yield takeEvery(types.FETCH_DRIVERS_EXCEL, workfetchDriversExcel);
}

export function* watchFetchDriversfilter() {
  yield takeEvery(types.FETCH_FILTER_DRIVERS, workfetchDriversfilter);
}
export function* watchFetchAvailDrivers() {
  yield takeEvery(types.FETCH_AVAIL_DRIVERS, workfetchAvailDrivers);
}
export function* watchCreatDriver() {
  yield takeEvery(types.CREATE_DRIVER, workCreateDriver);
}
export function* watchDeleteDriver() {
  yield takeEvery(types.DELETE_DRIVER, workDeleteDriver);
}
export function* watchEditDriverById() {
  yield takeEvery(types.EDIT_DRIVER_BYID, workEditDriverById);
}
export function* watchFetchDriverById() {
  yield takeEvery(types.FETCH_DRIVER_BYID, workFetchDriverById);
}
export function* watchFetchUploadDriver() {
  yield takeEvery(types.UPLOAD_DRIVER, workUploadDriver);
}
export function* watchSubscribeConsent() {
  yield takeEvery(types.SUBSCRIBE_CONSENT, workSubscribeConsent);
}
export function* watchUnSubscribeConsent() {
  yield takeEvery(types.UNSUBSCRIBE_CONSENT, workUnSubscribeConsent);
}
// export function* watchFetchDriverConcentStatus() {
//   yield takeEvery(types.REQUEST_CONSENT, workfetchDriverConcentStatus);
// }
// export function* watchFetchDriverAddConcent() {
//   yield takeEvery(types.ADD_CONSENT, workfetchDriverAddConcent);
// }
function* DriverSaga() {
  yield all([
    fork(watchFetchDrivers),
    fork(watchFetchDriversExcel),
    fork(watchFetchDriversfilter),
    fork(watchFetchAvailDrivers),
    fork(watchCreatDriver),
    fork(watchDeleteDriver),
    fork(watchEditDriverById),
    fork(watchFetchDriverById),
    fork(watchFetchUploadDriver),
    fork(watchSubscribeConsent),
    fork(watchUnSubscribeConsent),
    // fork(watchFetchDriverConcentStatus),
    // fork(watchFetchDriverAddConcent),
  ]);
}

export default DriverSaga;
