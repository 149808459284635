export const types = {
  FETCH_SOURCE_SECONDARY: "FETCH_SOURCE_SECONDARY",
  FETCH_SOURCE_SECONDARY_SUCCESS: "FETCH_SOURCE_SECONDARY_SUCCESS",

  FETCH_VEHICLES_TYPE_SECONDARY: "FETCH_VEHICLES_TYPE_SECONDARY",
  FETCH_VEHICLES_TYPE_SECONDARY_SUCCESS:
    "FETCH_VEHICLES_TYPE_SECONDARY_SUCCESS",

  FETCH_TRANSPORTER_SECONDARY: "FETCH_TRANSPORTER_SECONDARY",
  FETCH_TRANSPORTER_SECONDARY_SUCCESS: "FETCH_TRANSPORTER_SECONDARY_SUCCESS",

  FETCH_CUSTOMERS_SECONDARY: "FETCH_CUSTOMERS_SECONDARY",
  FETCH_CUSTOMERS_SECONDARY_SUCCESS: "FETCH_CUSTOMERS_SECONDARY_SUCCESS",

  CREATE_INDENT_SECONDARY: "CREATE_INDENT_SECONDARY",
  CREATE_INDENT_SECONDARY_SUCCESS: "CREATE_INDENT_SECONDARY_SUCCESS",

  COST_DISPLAY_FORM_SECONDARY: "COST_DISPLAY_FORM_SECONDARY",
  COST_DISPLAY_FORM_SECONDARY_SUCCESS: "COST_DISPLAY_FORM_SECONDARY_SUCCESS",

  SET_CUSTOMER_DROPDOWN: "SET_CUSTOMER_DROPDOWN",
  SET_CUSTOMER_DROPDOWN_SUCCESS: "SET_CUSTOMER_DROPDOWN_SUCCESS",

  SET_CUSTOMER_DROPDOWN_FALSE: "SET_CUSTOMER_DROPDOWN_FALSE",
  SET_CUSTOMER_DROPDOWN_FALSE_SUCCESS: "SET_CUSTOMER_DROPDOWN_FALSE_SUCCESS",

  SET_ASSIGNED_CUSTOMER_DROPDOWN: "SET_ASSIGNED_CUSTOMER_DROPDOWN",
  SET_ASSIGNED_CUSTOMER_DROPDOWN_SUCCESS:
    "SET_ASSIGNED_CUSTOMER_DROPDOWN_SUCCESS",

  SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE: "SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE",
  SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE_SUCCESS:
    "SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE_SUCCESS",

  FETCH_INDENTS_SECONDARY: "FETCH_INDENTS_SECONDARY",
  FETCH_INDENTS_SECONDARY_SUCCESS: "FETCH_INDENTS_SECONDARY_SUCCESS",

  FETCH_CUSTOMERS_OPTIONS_SECONDARY: "FETCH_CUSTOMERS_OPTIONS_SECONDARY",
  FETCH_CUSTOMERS_OPTIONS_SECONDARY_SUCCESS:
    "FETCH_CUSTOMERS_OPTIONS_SECONDARY_SUCCESS",

  FETCH_VEHICLETYPE_OPTIONS_SECONDARY: "FETCH_VEHICLETYPE_OPTIONS_SECONDARY",
  FETCH_VEHICLETYPE_OPTIONS_SECONDARY_SUCCESS:
    "FETCH_VEHICLETYPE_OPTIONS_SECONDARY_SUCCESS",

  FETCH_TRANSPORTER_OPTIONS_SECONDARY: "FETCH_TRANSPORTER_OPTIONS_SECONDARY",
  FETCH_TRANSPORTER_OPTIONS_SECONDARY_SUCCESS:
    "FETCH_TRANSPORTER_OPTIONS_SECONDARY_SUCCESS",

  FETCH_ASSIGNED_INDENTS_SECONDARY: "FETCH_ASSIGNED_INDENTS_SECONDARY",
  FETCH_ASSIGNED_INDENTS_SECONDARY_SUCCESS:
    "FETCH_ASSIGNED_INDENTS_SECONDARY_SUCCESS",

  SECONDARY_INDENT_STATUS_ACCEPT: "SECONDARY_INDENT_STATUS_ACCEPT",
  SECONDARY_INDENT_STATUS_ACCEPT_SUCCESS:
    "SECONDARY_INDENT_STATUS_ACCEPT_SUCCESS",

  SECONDARY_INDENT_STATUS_REJECT: "SECONDARY_INDENT_STATUS_REJECT",
  SECONDARY_INDENT_STATUS_REJECT_SUCCESS:
    "SECONDARY_INDENT_STATUS_REJECT_SUCCESS",

  FREIGHTS_MASTER_SECONDARY: "FREIGHTS_MASTER_SECONDARY",
  FREIGHTS_MASTER_SECONDARY_SUCCESS: "FREIGHTS_MASTER_SECONDARY_SUCCESS",

  FETCH_CUSTOMERS_MASTER_SECONDARY: "FETCH_CUSTOMERS_MASTER_SECONDARY",
  FETCH_CUSTOMERS_MASTER_SECONDARY_SUCCESS:
    "FETCH_CUSTOMERS_MASTER_SECONDARY_SUCCESS",

  VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY:
    "VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY",
  VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY_SUCCESS:
    "VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY_SUCCESS",

  VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY:
    "VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY",
  VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY_SUCCESS:
    "VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY_SUCCESS",

  CANCEL_TRIP_SECONDARY: "CANCEL_TRIP_SECONDARY",
  CANCEL_TRIP_SECONDARY_SUCCESS: "CANCEL_TRIP_SECONDARY_SUCCESS",
};
