import React, { Component } from "react";

import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  fetchVehicleTypesFilter,
  fetchVehicleTypesFilterExcel,
} from "../../store/vehicleType/actions";
import { deleteVehicleTypesSpec } from "../../store/vehicleType/actions";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import PopupVehicleType from "../../components/Common/PopupVehicleType";
import VehicleTypeForm from "../Forms/VehicleTypeAddForm";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import { actions } from "./actionButtons";
import QuickFilters from "./quckFilters";
import { exportToCSV } from "../common/exportToCsv";

// var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      searchFilters: {},
      setOpenPopup: false,
      viewById: {},
      deleteId: "",
    };
    if (process.env.REACT_APP_CLIENT != "3_BAXTER") {
      (async () => {
        let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
        if (redirect === false) {
          this.props.history.push("/operationsDashboard");
        }
      })();
    }
  }

  componentDidMount = () => {
    this.fetchVehicleType();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };
  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    this.getData();
  };
  addVehicle = (ele, viewMode) => {
    this.setState({ setOpenPopup: true, viewById: { ele, viewMode } });
  };

  fetchVehicleType = (data) => {
    this.setState({ dataLoading: true });
    data = data || {};
    this.props.fetchVehicleTypesFilter(offset, " ", (res) => {
      this.setState({ dataLoading: false });
    });
  };
  enterCheck = (event) => {
    if (event.key === "Enter") {
      this.getData();
    }
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  // filterList = () => {
  //   this.getData();
  // };
  handleSearchInput = (e, data) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => {});
  };

  getData = () => {
    this.setState({ dataLoading: true });
    this.props.fetchVehicleTypesFilter(
      0,
      {
        label: this.state.searchFilters?.label
          ? this.state.searchFilters?.label
          : "",

        vehicleTypeCode: this.state.searchFilters?.vehicleTypeCode
          ? this.state.searchFilters?.vehicleTypeCode
          : "",

        weight: this.state.searchFilters?.weight
          ? this.state.searchFilters?.weight
          : "",

        lengths: this.state.searchFilters?.length
          ? this.state.searchFilters?.length
          : "",

        width: this.state.searchFilters?.width
          ? this.state.searchFilters?.width
          : "",

        height: this.state.searchFilters?.height
          ? this.state.searchFilters?.height
          : "",
      },
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };
  reset = (e) => {
    offset = 0;
    searchValues = {};
    this.setState(
      {
        searchFilters: {
          vehicleTypeCode: "",
          label: "",
          height: "",
          length: "",
          width: "",
          weight: "",
        },
      },
      () => {
        this.setState({ dataLoading: true });
        this.props.fetchVehicleTypesFilter(0, " ", () => {
          this.setState({ dataLoading: false });
        });
      }
    );
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.fetchVehicleType();
  };

  editDetail = (ele) => {
    this.addVehicle(ele, "edit");
  };

  deleteVehicleSpec = (ele) => {
    // console.log("delete");
    this.setState({ deleteId: ele?._id ? ele?._id : "" }, () => {
      if (this.state.deleteId.length) {
        let id = this.state.deleteId;
        this.props.deleteVehicleTypesSpec(id, (res) => {
          if (res.success) {
            NotificationManager.success(res["message"], "Success");
            this.getData();
          } else {
            NotificationManager.error(res["message"], "Error");
            this.setState({ onSubmitDisable: false });
          }
        });
      }
    });
  };

  exportExcelData = () => {
    this.setState({ dataLoading: true }, () => {});
    // Call the action function using await to ensure proper execution
    this.props.fetchVehicleTypesFilterExcel(
      0,
      {
        label: this.state.searchFilters?.label
          ? this.state.searchFilters?.label
          : "",

        vehicleTypeCode: this.state.searchFilters?.vehicleTypeCode
          ? this.state.searchFilters?.vehicleTypeCode
          : "",

        weight: this.state.searchFilters?.weight
          ? this.state.searchFilters?.weight
          : "",

        lengths: this.state.searchFilters?.length
          ? this.state.searchFilters?.length
          : "",

        width: this.state.searchFilters?.width
          ? this.state.searchFilters?.width
          : "",

        height: this.state.searchFilters?.height
          ? this.state.searchFilters?.height
          : "",
      },
      (res) => {
        this.setState({ dataLoading: false }, () => {});
        // this.setState({ isExportingExcel: false });
        // let exdata=[]
        let exportData = [];
        if (res?.data?.docs?.length > 0) {
          res.data.docs.map((ele) => {
            // console.log("ele", ele);
            let lengthSpec =
              ele?.specs?.length !== undefined ? ele?.specs?.length : "N/A";
            let widthSpec =
              ele?.specs?.width !== undefined ? ele?.specs?.width : "N/A";
            exportData.push({
              "Vehicle Type Code": ele?.vehicleTypeCode
                ? ele?.vehicleTypeCode
                : "N/A",
              Label: ele?.label ? ele?.label : "N/A",
              Width: widthSpec,
              Length: lengthSpec,
              Height: ele?.specs?.height ? ele?.specs?.height : "N/A",
              Weight: ele?.specs?.weight ? ele?.specs?.weight : "N/A",
            });
          });
        }
        // console.log("export Data", exportData);
        if (exportData?.length > 0) {
          exportToCSV([...exportData], "Vehicle Type");
        } else {
          exportData.push({
            "Vehicle Type Code": "",
            Label: "",
            Width: "",
            Length: "",
            Height: "",
            Weight: "",
          });
          exportToCSV([...exportData], "Vehicle Type");
        }
      }
    );
  };

  render() {
    const { specOffset, specLength } = this.props;
    // const localStorageData = JSON.parse(
    //   localStorage.getItem("vehiclesListData")
    // );
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;

    let rows = [];
    let data = this.props.vehicleType?.docs;

    if (Array.isArray(data)) {
      offset = specOffset;
      totCnt = specLength;
      toVal = offset + this.props.vehicleType?.docs?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      if (data !== undefined && data.length > 0) {
        data.forEach((ele) => {
          let lengthSpec =
            ele?.specs?.length !== undefined ? ele?.specs?.length : "N/A";
          let widthSpec =
            ele?.specs?.width !== undefined ? ele?.specs?.width : "N/A";

          if (ele) {
            rows.push({
              vehicleTypeCode: ele?.vehicleTypeCode
                ? ele?.vehicleTypeCode
                : "N/A",
              label: ele?.label ? ele?.label : "N/A",
              specWidth: widthSpec,
              specLength: lengthSpec,
              specHeight: ele?.specs?.height ? ele?.specs?.height : "N/A",
              specWeight: ele?.specs?.weight ? ele?.specs?.weight : "N/A",
              image: ele?.image ? (
                <a href={ele?.image} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <img
                    src={ele?.image}
                    alt=""
                    style={{ width: "90px", height: "41px" }}
                  ></img>
                </a>
              ) : (
                "N/A"
              ),
              actions: actions(ele, {
                routingToEditdetail: this.editDetail,
                routingToDelete: this.deleteVehicleSpec,
              }),
            });
          }
        });
      }
    }

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          {getLocalStorage()}
          <QuickFilters
            toggleExcel={this.exportExcelData}
            addDriverFunc={this.addVehicle}
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            inputVal={this.state.searchFilters}
            form={this.state.form}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.getData}
            vehicleIcon={
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 1H1V8.22222H9.33333V1Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                  stroke={darkTheme ? "#b7a9ff" : "#2800FC"}
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            mode={darkTheme}
          />

          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.fetchVehicleType}
                rowHeight={"56px"}
                limit={data?.length}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.fetchVehicleType}
                rowHeight={"56px"}
                limit={data?.length}
                dataLoading={this.state.dataLoading}
              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>

          <PopupVehicleType
            title="Vehicle Type"
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
            mode={darkTheme}
            height="90%"
          >
            <VehicleTypeForm
              handleSubmit={this.handleSubmit}
              mode={darkTheme}
              viewData={this.state.viewById}
            />
          </PopupVehicleType>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchId: state.VehicleType?.vehicleType?.fetchId,
  policies: state.AccessPolicy.accessPolicies.policies,
  vehicleType: state.VehicleType?.vehicleType?.vehicleTypeSpec,
  specOffset: state.VehicleType?.vehicleType?.vehicleTypeSpec?.offset,
  specLength: state.VehicleType?.vehicleType?.vehicleTypeSpec?.length,
});

export default connect(mapStateToProps, {
  deleteVehicleTypesSpec,
  fetchVehicleTypesFilter,
  fetchVehicleTypesFilterExcel,
})(Vehicle);
