import React, { Component } from "react";

import { Row, Col, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from ".././restrict";
import { PERMS } from "../../enum/perms.enum";
import {fetchHopsfilter } from "../../store/hop/actions";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import { actions } from "./actionButtons";
import QuickFilters from "./quckFilters";
// var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};


class HopMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      searchFilters: {
        hopCode:"",
        label:"",
        addressLine1:"",
        addressLine2:"",
        addressLine3:"",
        latitude:"",
        longitude:"",
        city:"",
        state:"",
        pinCode:""
      },
    };
    if(process.env.REACT_APP_CLIENT != "3_BAXTER"){ 
    (async () => {
      let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);
      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }
  }

  componentDidMount = () => {
    offset =0
    this.getAllData()
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }



 
  enterCheck = (event) => {
    if (event.key === "Enter") {
      offset =0;
      this.getAllData();
    }
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

 
  handleSearchInput = (e, data) => {
    searchValues[e?.target?.name] = e?.target?.value;
    this.setState({ searchFilters: searchValues }, () => {});
  };

  getAllData = () => {
    this.setState({ dataLoading: true });
    this.props.fetchHopsfilter(
      {offset:offset ,limit:10},
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };
  reset = (e) => {
    offset = 0;
    searchValues = {};
    this.setState(
      {
        searchFilters: {
          hopCode:"",
          label:"",
          addressLine1:"",
          addressLine2:"",
          addressLine3:"",
          latitude:"",
          longitude:"",
          city:"",
          state:"",
          pinCode:""
        },
      },
      () => {
        this.getAllData()
      }
    );
  };

  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
     this.getAllData();
  };

 

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkTheme = mode === "dark__mode" ? true : false;

    let rows = [];
    let masterHops = this.props.masterHops?.data;

    if (Array.isArray(masterHops?.docs)) {
      totCnt = masterHops?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = offset + masterHops?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      // console.log(toVal,fromVal,toVal,totCnt,"00")
      if (masterHops !== undefined && masterHops?.docs?.length > 0) {
        masterHops.docs.forEach((ele) => {
          if (ele) {
            rows.push({
              hopCode: ele?.hopCode
                ? ele?.hopCode
                : "N/A",
              label: ele?.label ? ele?.label : "N/A",
              description: ele?.description ? ele?.description : "N/A",
              addressLine1: ele?.address?.addressLine1 ? ele?.address?.addressLine1 : "N/A",
              addressLine2: ele?.address?.addressLine2 ? ele?.address?.addressLine2 : "N/A",
              addressLine3: ele?.address?.addressLine3 ? ele?.address?.addressLine3 : "N/A",
              city: ele?.address?.city ? ele?.address?.city : "N/A",
              state: ele?.address?.state ? ele?.address?.state : "N/A",
              pinCode: ele?.address?.pinCode ? ele?.address?.pinCode : "N/A",
              latitude: ele?.location?.latitude ? ele?.location?.latitude : "N/A",
              longitude: ele?.location?.longitude ? ele?.location?.longitude : "N/A",
            });
          }
        });
      }
    }

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;

    return (
      <React.Fragment>
        <div /* className="page-content" */>
          {getLocalStorage()}
          <QuickFilters
            reset={this.reset}
            onInputChange={this.handleSearchInput}
            filters={this.state.searchFilters}
            handleSubmitChange={this.handleSubmitChange}
            enterCheck={this.enterCheck}
            filterList={this.getAllData}
            mode={darkTheme}
          />

          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={rows}
                searchBar={searchBar}
                fetchId={this.props.fetchId}
                getAllData={this.getAllData}
                rowHeight={"60px"}
                dataLoading={this.state.dataLoading}
              />
            )}
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  {" "}
                  <span style={{ color: darkTheme ? "#9D9D9D" : "#0C0C0C" }}>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
   masterHops :   state?.Location?.locations?.getMasterHops,
  fetchId: state?.Location?.locations?.fetchId
});

export default connect(mapStateToProps, {
  fetchHopsfilter
})(HopMaster);
