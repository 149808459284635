import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import plusIcon from "./assets/plus.png";
import plusDarkIcon from "./assets/plusDark.png";
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
import { InputGroup, Input } from "reactstrap";
import "./vehicleTheme.scss";
import refreshIcon from "./assets/refresh-cw.png";
import exportDarkIcon from "./assets/external-linkDark.png";
import exportIcon from "./assets/external-link.png";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
    };
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    let darkMode = this.props?.mode;
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar vehicleStyle darkBgQuickFilter">
            <>
              {/* <div className="d-flex justify-content-sm-end cardStyle"> */}
              <Row className="justify-content-space-between">
                {/* <Col xs={0} md={5}></Col> */}
                <Col xs={12} md={5} className="mobileDisplay">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Button
                       color=""
                        style={{
                          minWidth: "70%",
                        }}
                        className="mobile-reset reset"
                        onClick={() => this.props?.reset()}
                      >
                        <img
                          className="mobile-rest-img"
                          src={refreshIcon}
                          alt=""
                        ></img>
                      </Button>
                    </div>
                    <div className=" filter">
                      <div className="tooltipIcon">
                        <Button
                         color=""
                          className="iconButtons iconDarkButtons"
                          style={{
                            marginRight: "0px",
                          }}
                          onClick={() => this.props?.addDriverFunc()}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? plusDarkIcon : plusIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "100px" }}
                        >
                          &nbsp; {this.state.userLangs?.masters?.ADD_VEHICLE}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className=" filter">
                      <div className="tooltipIcon">
                        <Button
                         color=""
                          disabled={!navigator.onLine}
                          onClick={() => this.props.toggleExcel()}
                          className={`iconButtons ${
                            darkMode ? "iconDarkButtons" : ""
                          }`}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? exportDarkIcon : exportIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext"
                          style={{ left: "10%" }}
                        >
                          &nbsp;
                          {this.state.userLangs?.masters?.ADD_VEHICLE ??
                            "Download Excel"}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0">
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="vehicleTypeCode"
                          value={this.props.inputVal?.vehicleTypeCode}
                          onChange={(e) => this.props.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Vehicle Type Code"
                          aria-label="Search"
                          icon={
                            <svg
                              width="14"
                              height="12"
                              viewBox="0 0 14 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.33333 1H1V8.22222H9.33333V1Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          {this.props?.vehicleIcon}
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0">
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="label"
                          value={this.props.inputVal?.label}
                          onChange={(e) => this.props?.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Label"
                          aria-label="Search"
                          icon={
                            <svg
                              width="14"
                              height="12"
                              viewBox="0 0 14 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.33333 1H1V8.22222H9.33333V1Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.33203 3.7778H11.5543L13.2209 5.44447V8.22225H9.33203V3.7778Z"
                                stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3.50022 11C4.26728 11 4.88911 10.3782 4.88911 9.61112C4.88911 8.84406 4.26728 8.22223 3.50022 8.22223C2.73315 8.22223 2.11133 8.84406 2.11133 9.61112C2.11133 10.3782 2.73315 11 3.50022 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.7209 11C11.488 11 12.1098 10.3782 12.1098 9.61112C12.1098 8.84406 11.488 8.22223 10.7209 8.22223C9.95386 8.22223 9.33203 8.84406 9.33203 9.61112C9.33203 10.3782 9.95386 11 10.7209 11Z"
                                stroke="#2800FC"
                                strokeWidth="1.3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          {this.props?.vehicleIcon}
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0">
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control "
                          type="text"
                          name="length"
                          value={this.props.inputVal?.length}
                          onChange={(e) => this.props.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Length"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0">
                  <div className=" filter">
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="width"
                          value={this.props.inputVal?.width}
                          onChange={(e) => this.props.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Width"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0">
                  <div className=" filter"
                  //  style={{ marginRight: "20%" }}
                   >
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="height"
                          value={this.props.inputVal?.height}
                          onChange={(e) => this.props.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Height"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2} className="pl-0">
                  <div
                    className=" filter"
                    // style={{ width: "162%", marginLeft: "-42%" }}
                  >
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control "
                          type="text"
                          name="weight"
                          value={this.props.inputVal?.weight}
                          onChange={(e) => this.props.onInputChange(e)}
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Weight"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className="pl-1"
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={2} className="desktopDisplay ml-auto mt-3">
                  <div className="d-flex justify-content-end">
                    <div className=" filter mr-2">
                      {" "}
                      <div>
                        <Button
                         color=""
                          style={{
                            width: "90%",
                            paddingRight: "20px",
                          }}
                          onClick={() => this.props?.reset()}
                          className="reset"
                        >
                          {this.state.userLangs?.common?.RESET}
                        </Button>
                      </div>
                    </div>
                    <div className="filter mr-2">
                      <div className="tooltipIcon">
                        <Button
                         color=""
                          className="iconButtons iconDarkButtons"
                          onClick={() => this.props?.addDriverFunc()}
                        >
                          <img
                            style={{ margin: "-4px" }}
                            src={darkMode ? plusDarkIcon : plusIcon}
                            alt="exp"
                          />
                        </Button>
                        <span
                          className="tooltipIcontext"
                          style={{ width: "100px", left: "-8px" }}
                        >
                          &nbsp; {this.state.userLangs?.masters?.ADD_VEHICLE}
                          &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className=" filter mr-2">
                    <div className="tooltipIcon">
                      <Button
                       color=""
                        disabled={!navigator.onLine}
                        onClick={() => this.props.toggleExcel()}
                        className={`iconButtons ${
                          darkMode ? "iconDarkButtons" : ""
                        }`}
                      >
                        <img
                          style={{ margin: "-4px" }}
                          src={darkMode ? exportDarkIcon : exportIcon}
                          alt="exp"
                        />
                      </Button>
                      
                      <span className="tooltipIcontext" style={{ left: "10%" }}>
                        &nbsp;
                        {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* </div> */}
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
