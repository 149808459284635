import React, { Component } from "react";
import "./tripStyles.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import QuickFilters from "./quickFilters";
import Index from "../../../../../components/Common/DataTableNeo/Index";
import {
  getLocalStorage,
  HeaderData,
  searchBar,
  TransporterHeaderData,
} from "./RowHeaders/RowHeaders";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { MoonLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import {
  fetchSecondaryIndents,
  setCustomerDropDown,
  fetchSourceSecondary,
  fetchCustomerOptionsSecondary,
  fetchVehicletypeOptionsSecondary,
  fetchTransporterOptionsSecondary,
  setCustomerDropDownFalse,
} from "../../../../../store/secondaryIndentPlanning/actions";
import { connect } from "react-redux";
import formatDate from "../../../../DateFormatter";
import { actions } from "./actionButtons";
import moment from "moment";
import {
  fetchVehicles,
  fetchDrivers,
  createVehicle,
  createDriver,
} from "../../../../../store/actions";
import ApproveModal from "../../../../ApproveModal";
import CancelIndentModal from "../../../../CancelIndentModal";
import { Modal, ModalBody } from "reactstrap";
import IndentData from "../../../../indentData";
import { fetchCreatedIndentInfo } from "../../../../../store/actions";
import { fetchIndentsExcel } from "../../../../../store/actions";
import { darken } from "@material-ui/core";

const downArrow = require("../../../../../../src/assets/images/downArrow.svg");

const indentStatusMaster = [
  { label: "Assigned", value: "Assigned" },
  { label: "Accepted", value: "Accepted" },
  { label: "Finalized", value: "Finalized" },
  { label: "AutoRejected", value: "AutoRejected" },
  { label: "Expired", value: "Expired" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Rejected", value: "Rejected" },
  { label: "Reassigned", value: "Reassigned" },
  { label: "Drafted", value: "Drafted" },
];

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};
var darkMode;

class SecondaryIndent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingExcel: false,
      dataLoading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      modalApproval: false,
      modalIndentDetails: false,
      IndentData: [],
      cancelModal: false,

      data: [],
      filters: "",
      indentData: {},
      searchKey: "",
      searchValue: "",
      disabled: true,
      searchOn: false,
      keySelected: false,
      radioFilter: "",
      indentId: "",
      driver: "",
      vehicle: "",
      onSubmitDisableVehicle: false,
      onSubmitDisableDriver: false,
      export: false,
      toBeSelectedVehicleType: null,
      transporter: "",

      offset: 0,
      limit: 200,
      filterTranspoter: "",
      allTransporter: "",
      customers: [],
      checkChanged: false,
      headerData: undefined,
      active: false,
      vehicleForm: {},
      driverForm: {},
      transporterVehicleOptions: [],
      transporterDriverOptions: [],
      customerDropDown: [],
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getAllData();
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  filterDataTranspotersAndTrips = (e) => {
    this.filtersHops(e);
    this.filtersTranspoter(e);
  };

  draftTofinal = (ele) => {
    window.history.replaceState(null, null, "/");
    let data = ele;
    let [customerNumber, isChecked, customerName, shipToCode, value] =
      data.customers;

    data.source = {
      label: ele.source?.label ? ele.source.label : "",
      value: { id: ele.source._id },
    };

    data?.cutomers?.length > 0 &&
      data.customers.push(
        data.customers.map((item) => {
          return (
            (customerNumber = item.customerNumber ? item.customerNumber : ""),
            (isChecked = item.isChecked ? item.isChecked : ""),
            (customerName = item.customerName ? item.customerName : ""),
            (shipToCode = item.shipToCode ? item.shipToCode : ""),
            (value = { id: item._id ? item._id : "" })
          );
        })
      );

    data.transporter = {
      name: ele.transporter?.name ? ele.transporter.name : "",
      value: { id: ele.transporter?._id },
    };

    data.vehicle.type = {
      label: ele.vehicle.type.label ? ele.vehicle.type.label : "",
      value: { id: ele.vehicle.type._id ? ele.vehicle.type._id : "" },
    };
    data.startTime = ele.startTime ? ele.startTime : "---";
    data.expiryTime = ele.expiryTime ? ele.expiryTime : "---";
    data.rejectionHistory = ele.rejectionHistory;
    data.pickUpLocations = ele.pickUpLocations;
    data.comments = ele.comments;
    this.props.history.push({
      pathname: "/secondary-add-indent",
      indent: data,
      mode: "edit",
      indentID: ele._id,
    });
  };

  componentDidUpdate() {
    if (
      (this.state.headerData === undefined &&
        HeaderData[0]?.label === undefined) ||
      this.state.headerData?.label !== HeaderData.label
    ) {
      this.setState({
        headerData: HeaderData,
      });
      getLocalStorage();
    }
  }

  toggleCustomerDropdown = (index) => {
    let customerData = this.state?.customerDropDown;
    customerData[index] = !this.state?.customerDropDown[index];

    this.setState({
      customerDropDown: customerData,
      checkChanged: !this.state.checkChanged,
    });
    this.props.setCustomerDropDown(index);
  };

  resetSelection = () => {
    this.setState({ vehicle: "", driver: "" });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.props.setCustomerDropDownFalse();
      let customerData = this.state.customerDropDown;
      Object.entries(customerData).forEach(([key, value]) => {
        customerData[key] = false;
      });
      this.setState({
        customerDropDown: customerData,
        checkChanged: true,
      });
    }
  };

  status = (status, lastRejection, id) => {
    switch (status) {
      case "Accepted":
        return <div className="tile-text h6 accepted-color">{status}</div>;
      case "Rejected":
        return (
          <>
            <div id={"status-" + id}>
              <div className="tile-text h6 rejected-color">{status}</div>
            </div>
            {/* <UncontrolledTooltip
              placement="top"
              autohide={true}
              target={"status-" + id}
            >
              <div>
                <b>{lastRejection?.reason ? lastRejection?.reason : "N/A"}</b>
              </div>
              {lastRejection?.remark ? lastRejection?.remark : "N/A"}
            </UncontrolledTooltip> */}
          </>
        );
      case "AutoRejected":
        return (
          <>
            <div id={"status-" + id}>
              <div className="tile-text h6 rejected-color">{status}</div>
            </div>
            {/* <UncontrolledTooltip
              placement="top"
              autohide={true}
              target={"status-" + id}
            >
              <div>
                <b>{lastRejection?.reason ? lastRejection?.reason : "N/A"}</b>
              </div>
              {lastRejection?.remark ? lastRejection?.remark : "N/A"}
            </UncontrolledTooltip> */}
          </>
        );
      case "Assigned":
        return <div className="tile-text h6 assigned-color">{status}</div>;
      case "Reassigned":
        return <div className="tile-text h6 assigned-color">{status}</div>;
      case "Finalized":
        return <div className="tile-text h6 finalized-color">{status}</div>;
      case "Drafted":
        return <div className="tile-text h6 drafted-color">{status}</div>;
      case "Expired":
        return <div className="tile-text h6 expired-color">{status}</div>;
      case "Cancelled":
        return <div className="tile-text h6 cancelled-color">{status}</div>;
      default:
        break;
    }
  };

  quickFilters = (filterData, dateValue) => {
    let searchFilters = this.state.searchFilters;
    if (filterData === "rejected") {
      searchFilters.status = "Rejected";
      this.setState({ active: true });
    }
    if (filterData === "date") {
      let date = [];
      date[0] =
        dateValue[0] !== null
          ? moment(dateValue[0]).format("MM-DD-YYYY")
          : null;
      date[1] =
        dateValue[1] !== null
          ? moment(dateValue[1]).format("MM-DD-YYYY")
          : null;
      searchFilters.startTime = date;
    }
    this.setState({ searchFilters: searchFilters }, () => {
      this.getAllData();
    });
  };

  toggleIndentModal = (ele) => {
    this.props.fetchCreatedIndentInfo(ele?._id, (result) => {
      this.setState({ IndentData: result.data }, () => {});
      this.setState({ modalIndentDetails: !this.state.modalIndentDetails });
    });
  };

  toggleRejectModal = (ele) => {
    this.setState(
      {
        cancelModal: !this.state.cancelModal,
        indentId: ele?._id,
        indent_id: ele?.indentId,
      },
      () => {}
    );
  };

  toggleModalApproval = (ele) => {
    this.setState({
      modalApproval: !this.state.modalApproval,
      vehicleForm: {},
      driverForm: {},
    });
    this.setState(
      {
        toBeSelectedVehicleType: {
          value: ele?.vehicle?.type._id ? ele?.vehicle?.type._id : "",
          label: ele?.vehicle?.type?.label ? ele.vehicle?.type?.label : "",
        },
      },
      () => {}
    );
    this.props.fetchDrivers(
      0,
      "",
      "",
      {
        q: "",
        transporterId: ele?.transporter?._id,
        // noLimit: true,
      },
      (res) => {
        this.setState({ transporterDriverOptions: res.data.docs });
      }
    );
    // debugger
    this.props.fetchVehicles(
      0,
      {
        transporterId: ele?.transporter?._id,
        // vehicleTypeId: ele?.vehicle.type?._id,
        refNo: ele?.vehicle?.refNo,
        // noLimit: true,
      },
      (res) => {
        this.setState({ transporterVehicleOptions: res.data.docs });
      }
    );

    this.setState(
      {
        indentId: ele?._id ? ele?._id : "---",
        indent_id: ele?.indentId,
        transporter: ele?.transporter?._id,
        vehicle: {
          value: ele?.vehicle?.id,
          label: ele?.vehicle?.label ? ele?.vehicle?.label : "Select",
        },
      },
      () => {}
    );
  };

  handleChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  filterVehicleList = (e) => {
    if (e.length > 2) {
      this.props.fetchVehicles(
        null,
        {
          q: e,
          regNo: e,
          transporterId: this.state.transporter,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
    if (e.length === 0) {
      this.props.fetchVehicles(
        0,
        {
          transporterId: this.state.transporter,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
  };

  filterDriverList = (e) => {
    var regexNumber = /^[0-9]+$/;
    var regexString = /^[a-zA-Z]+$/;
    if (e.length > 2) {
      if (e.match(regexNumber)) {
        this.props.fetchDrivers(offset, "", "", { phone: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
      }
      if (e.match(regexString))
        this.props.fetchDrivers(offset, "", "", { name: e }, (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        });
    }
    if (e.length === 0) {
      this.props.fetchDrivers(
        offset,
        "",
        "",
        {
          q: "",
          transporterId: this.state.transporter,
        },
        (res) => {
          this.setState({ transporterDriverOptions: res.data.docs });
        }
      );
    }
  };

  handleSubmitSaveVehicle = () => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state?.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner ? VehicleOwner : "",
      transporter: this.state?.transporter,
      regNo: VehicleRegistrationNumber ? VehicleRegistrationNumber : "",
      type: type?.value ? type?.value : "",
    };
    this.setState({ onSubmitDisableVehicle: true });
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        this.props.fetchVehicles(
          0,
          { transporterId: this.state.transporter },
          (res) => {
            this.setState({ transporterVehicleOptions: res.data.docs });
          }
        );
        NotificationManager.success(res["message"], "Success");
        this.setState({ onSubmitDisableVehicle: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisableVehicle: false,
          vehicle: "",
          vehicleForm: {
            VehicleOwner: "",
            VehicleRegistrationNumber: "",
            type: null,
          },
        });
      }
    });

    setTimeout(() => {
      let vh;
      if (this.state.vehicleForm.VehicleRegistrationNumber !== "")
        if (Array.isArray(this.state.transporterVehicleOptions)) {
          vh = this.state.transporterVehicleOptions.filter((v) => {
            return (
              v?.registration?.number ===
              this.state?.vehicleForm?.VehicleRegistrationNumber
            );
          });
          this.setState({
            vehicle: {
              value: vh[0]?._id,
              label:
                vh[0]?.registration?.number + " (" + vh[0]?.type?.label + ")",
            },
            vehicleForm: {
              VehicleOwner: "",
              VehicleRegistrationNumber: "",
              type: null,
            },
          });
        }
    }, 4000);
  };

  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name === "vehicleType") {
      vf.type = value;
    }
    if (name === "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name === "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value.toString().toUpperCase();
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      transporter: this.state.transporter,
      phone,
      licence: { number: driverLicenseNo },
    };
    this.setState({ onSubmitDisableDriver: true });
    if (Object.keys(formObj.phone).length > 0) {
      this.props.createDriver(formObj, (res) => {
        if (res.message === "Driver has been created successfully") {
          this.props.fetchDrivers(
            0,
            "",
            "",
            { q: "", transporterId: this.state.transporter },
            (res) => {
              this.setState({
                transporterDriverOptions: res.data.docs,
              });
            }
          );

          NotificationManager.success(res["message"], "Success");
          this.setState({ onSubmitDisableDriver: false });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({
            onSubmitDisableDriver: false,
            driver: "",
            driverForm: {
              fullName: "",
              phone: "",
              driverLicenseNo: "",
              transporter: "",
            },
          });
        }
      });
    } else {
      NotificationManager.error(" Phone Field required");
    }
    setTimeout(() => {
      let dt;
      if (this.state.driverForm.phone !== "") {
        if (Array.isArray(this.state.transporterDriverOptions)) {
          dt = this.state.transporterDriverOptions.filter((v) => {
            return v.phone === this.state.driverForm.phone;
          });
        }
        this.setState({
          driver: {
            value: dt[0]?._id,
            label: dt
              ? dt[0]?.phone + " (" + (dt[0]?.name ? dt[0]?.name : "N/A") + ")"
              : this.state.driverShow.phone,
          },
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    }, 4000);
  };

  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name === "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name === "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name === "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };

  handleChangePage = (event, val) => {
    // let key;
    this.setState({ dataLoading: true });

    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.props.fetchSecondaryIndents(
      offset,
      this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );
  };

  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "object") {
      if (type === "date") searchValues[name] = value;
      else searchValues[name] = value.id;
    } else {
      searchValues[name] = value;
    }
    this.setState({ searchFilters: searchValues }, () => {
      localStorage.setItem(
        "SecondaryIndentFilters",
        JSON.stringify(this.state.searchFilters)
      );
      if (type === "select" || type === "date") this.getAllData();
    });
  };

  reset = () => {
    this.setState(
      { searchFilters: {}, active: false, dataLoading: true },
      () => {
        searchValues = {};
        this.props.fetchSecondaryIndents(0, this.state.searchFilters, (res) => {
          this.setState({ dataLoading: false });
        });
      }
    );
    localStorage.removeItem("SecondaryIndentFilters");
  };

  fetchAllTransporterSearch = () => {
    this.props.fetchTransporterOptionsSecondary((res) => {
      let transporter = [];
      if (res.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          transporter.push({
            value: ele._id ? ele._id : "",
            label:
              (ele.name ? ele.name : "NA") +
              " - " +
              (ele.userId ? ele.userId : "NA") +
              (ele.email ? " - " + ele.email : " - NA"),
          });
        });
      }
      this.setState(
        {
          allTransporter: transporter,
        },
        () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "transporter") {
              obj.options = this.state.allTransporter;
            }
          });
        },
        () => {}
      );
    });
  };

  filtersTranspoter = (e) => {
    if (e?.length > 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchAllTransporterSearch(filterData);
    }
    if (e?.length === 0) {
      let filterData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchAllTransporterSearch(filterData);
    }
  };

  fetchHops = () => {
    this.props.fetchSourceSecondary((res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "source") {
              obj.options = this.state.hops;
            }
          });
        });
      }
    });
  };

  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData); //<<<left from here
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  getAllData = () => {
    let SecondaryFilterData = localStorage.getItem("SecondaryIndentFilters");

    this.props.fetchSecondaryIndents(
      (offset = 0),
      JSON.parse(SecondaryFilterData) || this.state.searchFilters,
      (res) => {
        this.setState({ dataLoading: false });
      }
    );

    this.props.fetchSourceSecondary((res) => {
      this.hops = [];
      if (res?.data?.length > 0) {
        // this.hops = [this.initialVal("Location")];
        res.data.forEach((ele) => {
          this.hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        this.setState({ hops: this.hops }, () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "source") {
              obj.options = this.state.hops;
            }
          });
        });
      }
    });

    this.props.fetchCustomerOptionsSecondary((res) => {
      let customers = [];
      if (res?.data?.docs?.length > 0) {
        res.data.docs.forEach((ele) => {
          customers.push({
            value: ele._id ? ele._id : "",
            label: `${ele.customerName ? ele.customerName : "N/A"} - ${
              ele.customerNumber ? ele.customerNumber : "N/A"
            } - ${ele.shipToCode ? ele.shipToCode : ele.shipToCode} `,
          });
        });
      }

      this.setState(
        { customers: customers },
        () => {
          searchBar.searchBarData.forEach((obj) => {
            if (obj.field === "customers") {
              obj.options = this.state.customers;
            }
          });
        },
        () => {}
      );
    });

    this.props.fetchVehicletypeOptionsSecondary((res) => {
      let type = [];
      if (Array.isArray(res.data.docs)) {
        if (res.data.docs.length) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.label,
              value: {
                id: ele._id,
              },
            });
          });
        }
      }

      this.setState({ vehicleTypes: type }, () => {
        searchBar.searchBarData.forEach((obj) => {
          if (obj.field === "vehicleType") {
            obj.options = this.state.vehicleTypes;
          }
        });
      });
    });

    this.fetchAllTransporterSearch();

    searchBar.searchBarData.forEach((obj) => {
      if (obj.field === "status") {
        obj.options = indentStatusMaster;
      }
    });
  };

  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false, loading: false });
  };

  excelRoutePublish = (ele) => {
    let routeString = "";

    ele.map((item, index) => {
      if (index === 0) routeString = routeString + item.label;
      else routeString = routeString + "->" + item.label;
      return true;
    });
    return routeString;
  };

  getCustomersExcel = (ele) => {
    console.log(ele.customerDetails);

    let customersArray = [];
    customersArray = ele.customers?.map((e, i) => {
      return `${e?.customerName} - ${e?.customerNumber} - ${e?.shipToCode} - ${e?.address?.city}`;
    });
    console.log(customersArray);
    return customersArray.join(",");
  };

  toggleExcel = async () => {
    let offset = 0;
    setTimeout(() => {
      this.setState({
        loadingExcel: true,
      });
      this.props.fetchSecondaryIndents(
        offset,
        this.state.searchFilters,
        (res) => {
          this.setState(
            {
              loadingExcel: false,
            },
            () => {
              let indents = [];
              if (Array.isArray(res?.data?.docs)) {
                res.data.docs.forEach((ele, i) => {
                  indents.push({
                    IndentId: ele?.indentId ? ele?.indentId : "---",
                    Source: ele?.source?.label || "---",
                    Destination: ele?.destination?.label || "---",
                    Vehicle: ele?.vehicle?.type?.label || "---",
                    Transporter: ele?.transporter?.name || "---",
                    Customers: ele.customers
                      ? this.getCustomersExcel(ele)
                      : "N/A",

                    // description: ele.description,
                    StartTime: ele?.startTime
                      ? moment(ele?.startTime).format("DD-MM-YYYY h:mm A", true)
                      : "---",
                    ExpiryTime: ele?.expiryTime
                      ? moment(ele?.expiryTime).format(
                          "DD-MM-YYYY h:mm A",
                          true
                        )
                      : "---",
                    Status: ele?.status,
                    "Expired Comment":
                      ele?.status === "Expired"
                        ? "Auto Rejection by System due to indent Not Accepted"
                        : "",
                    "Cancelled Comment": ele?.cancellationComment,
                  });
                });
              }
              if (indents?.length > 0) {
                this.exportToCSV([...indents], "SECONDARY_INDENT_DATA_EXCEL");
              }
            }
          );
        }
      );
    }, 1000);
  };

  onClickAddIndent = () => {
    this.props.history.push({
      pathname: "/secondary-add-indent",
    });
  };

  handleResetEdit = () => {
    this.setState({
      vehicle: "",
      driver: "",
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
    });
  };

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;

    if (this.state.headerData === undefined) {
      this.setState({
        headerData: HeaderData,
      });
    }

    const { loading } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }

    let vehicleType = this.props.vehicleType.vehicleType;
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    let LocalData = JSON.parse(localStorage.getItem("SecondaryIndentData"));
    var PWATest = navigator.onLine ? this.props.indents?.data : LocalData?.data;
    let data = [];
    if (Array.isArray(PWATest?.docs)) {
      data = [];
      offset = PWATest?.offset;
      totCnt = PWATest?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = PWATest?.offset + PWATest?.docs?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      PWATest.docs.forEach((ele, index) => {
        data.push({
          _id: ele._id ? ele._id : undefined,
          indentId: ele.indentId ? ele.indentId : "---",
          source: ele.source?.label || "---",
          customers:
            (
              <>
                <div className="tooltipIconComment">
                  <div className="ellipsis-text-label">
                    {ele?.customers.length > 0 ? (
                      <div className="divDropdown">
                        <div className="divDropdownText">
                          <div className="ellipsis-customerName">
                            {ele.customers[0].customerName}
                          </div>
                          <div>
                            - {ele.customers[0].customerNumber} -{" "}
                            {ele.customers[0].shipToCode} -{" "}
                          </div>
                          <div className="ellipsis-city">
                            {ele.customers[0].address.city}
                          </div>
                        </div>
                        <div
                          className="downImgDropdown"
                          onClick={() => this.toggleCustomerDropdown(index)}
                        >
                          <img src={downArrow} />
                        </div>
                      </div>
                    ) : (
                      "N/A"
                    )}
                    {ele?.customers.length > 1 ? (
                      <>
                        <div>...{ele?.customers.length - 1}</div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {ele.isDropdownOpen && (
                    <div
                      className="customersListDropDown"
                      ref={this.wrapperRef}
                    >
                      {ele?.customers.map((item, index) => {
                        return (
                          <div className="customerListDropdownText" key={index}>
                            {item.customerName} - {item.customerNumber} -{" "}
                            {item.shipToCode} - {item.address.city}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            ) || "---",
          vehicleType: ele?.vehicle?.type?.label || "---",
          transporter: ele?.transporter?.name || "---",
          route: ele?.route?.via?.length ? (
            <>{this.route(ele?.route?.via)}</>
          ) : (
            "DIRECT"
          ),
          // description: ele.description,
          startTime: ele?.startTime ? formatDate(ele?.startTime) : "---",
          expiryTime: ele?.expiryTime ? formatDate(ele?.expiryTime) : "---",
          status:
            this.status(ele?.status, ele?.lastRejection, ele?._id) || "---",
          actions: actions(ele?.status, ele, {
            routingToIndentDataModel: this.toggleIndentModal,
            routingToCancelIndentModel: this.toggleRejectModal,
            routingToProvideDetailModel: this.toggleModalApproval,
            routingToDraftToFinal: this.draftTofinal,
          }),
        });
      });
    }

    if (Array.isArray(this.state.transporterVehicleOptions)) {
      this.state.transporterVehicleOptions.forEach((ele) => {
        vehicleOptions.push({
          value: ele?._id,
          label: ele?.registration?.number + " (" + ele?.type?.label + ")",
        });
      });
    }

    if (Array.isArray(vehicleType?.data?.docs)) {
      vehicleType.data.docs.forEach((ele) => {
        vehicleTypeOptions.push({
          value: ele?._id,
          label: ele?.vehicleTypeCode,
        });
      });
    }
    if (Array.isArray(this.state.transporterDriverOptions)) {
      this.state.transporterDriverOptions.forEach((ele) => {
        driverOptions.push({
          value: ele?._id,
          label: ele?.phone + " (" + ele?.name + ")",
        });
      });
    }

    return (
      <div>
        {getLocalStorage()}
        <QuickFilters
          onClickAddIndent={this.onClickAddIndent}
          quickFilters={this.quickFilters}
          reset={this.reset}
          toggleExcel={this.toggleExcel}
          export={this.state.export}
          activeState={this.state.active}
          mode={darkMode ? darkMode : ""}
        />
        <Container
          fluid
          className="darkBackgroundList mobilepPadding"
          style={{ maxHeight: "96vh", overflow: "auto" }}
        >
          {userType && userType === "transporter" ? (
            <Index
              {...this.props}
              headers={TransporterHeaderData}
              data={this.state.data}
              searchBar={searchBar}
              searchable={true}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllData}
              dataLength={this.props.indents?.data?.length}
              fetchId={this.props.fetchId}
              toggle={this.toggleIndentModal}
              dataLoading={this.state.dataLoading}
              filterDataTranspotersAndTrips={this.filterDataTranspotersAndTrips}
            />
          ) : (
            <Index
              {...this.props}
              headers={HeaderData}
              data={data}
              checkChanged={this.state.checkChanged}
              searchBar={searchBar}
              searchable={true}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllData}
              dataLength={this.props.indents?.data?.length}
              fetchId={this.props.fetchId}
              toggle={this.toggleIndentModal}
              dataLoading={this.state.dataLoading}
              filterDataTranspotersAndTrips={this.filterDataTranspotersAndTrips}
            />
          )}

          {data.length > 0 && !this.state.dataLoading ? (
            totCnt !== 0 ? (
              <Row
                style={{
                  color: darkMode ? "#9D9D9D" : "#0C0C0C",
                  paddingTop: "150px !important",
                }}
                className="mb-2 paginationRow  ptd10"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span className="spanPagePadding">
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null
          ) : (
            ""
          )}
        </Container>
        <ApproveModal
          handleResetEdit={this.handleResetEdit}
          isOpen={this.state.modalApproval}
          toggle={this.toggleModalApproval}
          onChange={this.handleChangeModal}
          options={vehicleOptions}
          optionsdriver={driverOptions}
          driver={this.state.driver}
          vehicle={this.state.vehicle}
          submit={this.handleSubmitApprove}
          disabledVehicle={this.state.onSubmitDisableVehicle}
          disabledDriver={this.state.onSubmitDisableDriver}
          indentIndex={this.state.indentId}
          indent_id={this.state.indent_id}
          filterVehicleList={this.filterVehicleList}
          filterDriverList={this.filterDriverList}
          type={vehicleTypeOptions}
          refreshData={this.getAllData}
          submitVehicleForm={this.handleSubmitSaveVehicle}
          onVehicleChange={this.onVehicleChange}
          vehicleForm={this.state.vehicleForm}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          resetSelection={this.resetSelection}
          toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
          transporter={this.state.transporter}
          indentPageRoute={true}
          mode={darkMode}
        />
        <CancelIndentModal
          isOpen={this.state.cancelModal}
          toggle={this.toggleRejectModal}
          indentIndex={this.state.indentId}
          refreshData={this.getAllData}
          IndentId={this.state.indent_id}
          mode={darkMode}
        />
        <Modal
          isOpen={this.state.modalIndentDetails}
          toggle={this.toggleIndentModal}
          size="lg"
        >
          <ModalBody
            className={`indentModal ${
              darkMode ? "darkIndentModalDetails" : ""
            }`}
          >
            <IndentData
              data={this.state.IndentData}
              formatDate={formatDate}
              routeConvert={"Direct"}
              header={"Indent Details"}
              closeModal={this.toggleIndentModal}
              mode={darkMode}
              indentType={"secondary"}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    ...state,
    indents: state.SecondaryIndent.secondaryIndents.creatorIndents,
    fetchId: state.SecondaryIndent.secondaryIndents.fetchId,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    vehicleType: state.VehicleType.vehicleType,
    indentsExcel: state.Indent.indents.excelIndents,
  };
};

export default connect(mapStateToProps, {
  fetchSecondaryIndents,
  setCustomerDropDown,
  fetchSourceSecondary,
  fetchCustomerOptionsSecondary,
  fetchVehicletypeOptionsSecondary,
  fetchTransporterOptionsSecondary,
  createVehicle,
  fetchVehicles,
  fetchDrivers,
  createDriver,
  fetchCreatedIndentInfo,
  fetchIndentsExcel,
  setCustomerDropDownFalse,
})(SecondaryIndent);
