import React, { Component } from "react";
import { connect } from "react-redux";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Card,
  Container,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import { fetchSignedURL_LRFront } from "../../../store/actions";
import { tripEpod } from "../../../store/trip/actions";
import {
  fetchShipmentTripById,
  storeShipmentData,
} from "../../../store/actions";
import QuickSearchFilters from "./QickSeachFilter";
import plusIcon from "../assets/plus.png";
import plusDarkIcon from "../assets/plusDark.png";
import DamageShortageDetails from "./DamageShortageDetails";
import EPODModal from "./EPODModal";

var darkMode;
var fileObj = [];
var fileArray = [];

class EPODIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tripID: "",
      shipmentData: [],
      shipmentDataDamaged: {},
      shipmentItemData: {},
      tripDetails: {},
      shipmentDataShow: null,
      damageShortageModalToggle: false,
      showEPODModal: false,
      frontImages: [],
      backImages: [],
      unloadingImages: [],
      itemDetailsData: {},
      unloadingCharge: "",
    };
  }

  toggle_shipmentDataShow = (index) => {
    console.log(index);

    this.setState({
      shipmentDataShow: this.state.shipmentDataShow === index ? null : index,
    });
  };

  itemData = (data, shipmentData) => {
    // console.log("Data123", data, shipmentData);
    this.setState(
      {
        shipmentItemData: data,
        shipmentDataDamaged: shipmentData,
      },
      () => {
        this.openDamageShortageModal();
      }
    );
  };
  EPODModalToggle = async () => {
    this.setState({ showEPODModal: !this.state.showEPODModal }, () => {
      return this.state.showEPODModal;
    });
  };
  openDamageShortageModal = () => {
    this.setState({
      damageShortageModalToggle: !this.state.damageShortageModalToggle,
    });
  };

  openDamageShortageModalClose = () => {
    this.setState({ damageShortageModalToggle: false });
  };
  handleChangeCharge = (e) => {
    this.setState({
      unloadingCharge: e.target.value,
    });
  };
  componentDidMount = () => {
    //still to write

    this.setState(
      {
        tripID: this.props.history.location.state.tripDetailsForEPOD._id,
      },
      () => {
        this.props.fetchShipmentTripById(this.state.tripID, (res) => {
          if (res.success === true) {
            NotificationManager.success(res["message"], "Success");
            console.log("TRIP DATA", res);
            this.setState(
              {
                tripDetails: res.data,
                shipmentData: res.data,
              },
              () => {
                this.props.storeShipmentData(this.state.shipmentData);
                // console.log(
                //   "PROPS",
                //   this.state.tripDetails,
                //   "[][][][]",
                //   this.state.shipmentData
                // );
              }
            );
          } else if (res?.success === false) {
            NotificationManager.error(res["message"], "Error");
          } else {
            NotificationManager.error(res["message"], "Error");
          }
        });
      }
    );
  };
  acksubmit = (frontImages, backImages, unloadingImages) => {
    // console.log("frontImages", frontImages);
    // console.log("backImages", backImages);
    // console.log("unloadingImages", unloadingImages);
    this.setState(
      {
        frontImages: [...frontImages],
        backImages: [...backImages],
        unloadingImages: [...unloadingImages],
      },
      () => {
        this.handleSubmitTrip();
      }
    );
  };
  handleSubmitDamged = (itemDetails) => {
    // console.log("item Details", itemDetails);
    this.setState(
      {
        itemDetailsData: itemDetails,
      },
      () => {
        // console.log("itemDetailsData", this.state.itemDetailsData);
        const shipmentData = this.state.shipmentData;
        if (this.state.shipmentData !== undefined) {
          this.state.shipmentData.forEach((ele, indexship) => {
            if (ele?.shipmentId === this.state.itemDetailsData?.shipmentId) {
              const test1 = ele?.items.forEach((eleItem, index) => {
                if (
                  eleItem?.itemId ===
                  this.state.itemDetailsData?.itemDetails?.itemId
                )
                  this.state.shipmentData[indexship].items[index] =
                    this.state.itemDetailsData?.itemDetails;
              });
            }
          });
          // console.log("shipmentData Test", this.state.shipmentData);
          this.props.storeShipmentData(this.state.shipmentData);
        }
        // this.handleSubmitTrip();
      }
    );
  };
  handleSubmitTrip = () => {
    let epodCreate;
    let shipments = [];
    const shipmentData = this.props?.shipmentItemDataStoret;
    if (this.props?.shipmentItemDataStore !== undefined) {
      this.props.shipmentItemDataStore.forEach((ele, index) => {
        // console.log("ele", ele);
        shipments.push({
          source: ele?.source,
          destination: ele?.destination,
          isActive: ele?.isActive,
          isDeleted: ele?.isDeleted,
          _id: ele?._id,
          tripId: ele?.tripId,
          shipmentId: ele?.shipmentId,
          items: ele?.items,
          createdAt: ele?.createdAt,
          updatedAt: ele?.updatedAt,
          updatedBy: ele?.updatedBy,
          __v: ele?.__v,
        });
      });
    }
    if (this.props.history?.location?.state?.tripDetailsForEPOD !== undefined) {
      epodCreate = {
        tripID: this.props.history?.location?.state?.tripDetailsForEPOD?._id,
        shipments: shipments,
        unloadingCharge: this.state.unloadingCharge,
        unloadingReceiptImages: this.state.unloadingImages,
        lrImages: {
          front: this.state.frontImages,
          back: this.state.backImages,
        },
      };
    }
    this.props.tripEpod(epodCreate, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        // this.props.getAllData();
        this.handleReset();
        this.props.toggleModel();
      } else {
        NotificationManager.error(res["message"], "Error");
      }
    });
    this.props.history.push({
      pathname: "/trips",
    });
    // window.location.reload();
    // console.log("epodCreate", epodCreate);
  };
  render() {
    // console.log("this.props?.shipmentItemDataStore",this.props?.shipmentItemDataStore)
    // console.log("history props", this.props);
    // console.log("shipmentData", this.state.shipmentData);
    // console.log(
    //   "props",
    //   this.props.history?.location?.state?.tripDetailsForEPOD
    // );
    let TripData = this.props.history?.location?.state?.tripDetailsForEPOD;
    // console.log(
    //   "Test",
    //   TripData.tracking.path[TripData.tracking.path.length - 1].operations
    //     .loading
    // );
    let current = TripData.tracking.current;
    let epodFlag = false;
    let DestinationNextID =
      this.props.history?.location?.state?.tripDetailsForEPOD?.tracking?.next;
    TripData.tracking.path.forEach((ele) => {
      if (ele?._id === current) {
        ele.operations.loading === null
          ? (epodFlag = true)
          : (epodFlag = false);
      }
    });

    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    const tripDetailsForEPODData =
      this.props.history?.location?.state?.tripDetailsForEPOD;
    return (
      <React.Fragment>
        <EPODModal
          isOpen={this.state.showEPODModal}
          toggle={this.EPODModalToggle}
          tripDetailsForEPOD={tripDetailsForEPODData}
          mode={darkMode}
          acksubmit={this.acksubmit}
        />
        <DamageShortageDetails
          id={this.state.tripID}
          isOpen={this.state.damageShortageModalToggle}
          toggle={this.openDamageShortageModal}
          closeModal={this.openDamageShortageModalClose}
          // lrStatus={this.props.lrStatus}
          tripDetailsForEPOD={tripDetailsForEPODData}
          darkTheme={darkMode}
          tripDetails={this.state.tripDetails}
          shipmentData={this.state.shipmentData}
          shipmentItemData={this.state.shipmentItemData}
          shipmentDataDamaged={this.state.shipmentDataDamaged}
          handleSubmitDamged={this.handleSubmitDamged}
          mode={darkMode}
        />
        <Container
          fluid
          style={{
            paddingBottom: "69px",
            height: "112vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {/* <div style={{ height: "100%" }}>
            <QuickSearchFilters />
          </div> */}
          <Card className="mb-2 darkMultipleCharges">
            <div className="p-2 mt-2 mb-2">
              <span className="fontRoboto15 pl-2 light__span">Trip Id:</span>
              <span className="fontRoboto154 pl-2 dark__span">
                {TripData?.tripId}
              </span>
            </div>
          </Card>
          {console.log(this.props?.shipmentItemDataStore)}
          {this.props?.shipmentItemDataStore?.length > 0 ? (
            this.props?.shipmentItemDataStore?.map((item, indexShipment) => {
              console.log("MAP", item, indexShipment);
              return (
                <>
                  <Row key={indexShipment}>
                    <Col xs={12}>
                      <Card className="addAgreementCard darkMultipleCharges">
                        <Row>
                          <Col xs={10}>
                            <span className="fontRoboto15 span-names-agreement light__span">
                              Shipment No: {item.shipmentId}
                            </span>
                          </Col>
                          <Col xs={2}>
                            <div className="tooltipIcon float-right pr-4 pt-2">
                              <Button
                                className="iconButtons indentExpiry"
                                onClick={() =>
                                  this.toggle_shipmentDataShow(indexShipment)
                                }
                              >
                                <img
                                  style={{ margin: "-4px" }}
                                  src={darkMode ? plusDarkIcon : plusIcon}
                                  alt="exp"
                                />
                              </Button>
                              <span
                                className="tooltipIcontext"
                                style={{ width: "115px", left: "-8px" }}
                              >
                                Open Items
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    {this.state.shipmentDataShow === indexShipment ? (
                      <>
                        <Col
                          xs={12}
                          className={
                            this.state.shipmentData?.length > 10
                              ? "DataTableSub"
                              : ""
                          }
                        >
                          <Card
                            style={{
                              height:
                                this.state.shipmentData?.length > 10
                                  ? "700px"
                                  : "auto",
                            }}
                          >
                            {this.state.shipmentData[indexShipment]?.items?.map(
                              (item, index) => {
                                return (
                                  <Row key={index}>
                                    <Col
                                      xs={12}
                                      style={{
                                        backgroundColor: darkMode
                                          ? "#1b1c31"
                                          : "#FFF",
                                      }}
                                    >
                                      <Card className="addAgreementCard darkMultipleCharges">
                                        <Row>
                                          <Col xs={10}>
                                            <span className="fontRoboto15 span-names-agreement light__span">
                                              Item No: {item.itemId}
                                            </span>
                                          </Col>
                                          <Col xs={2}>
                                            <div className="tooltipIcon float-right pr-4 pt-2">
                                              <Button
                                                className="iconButtons indentExpiry"
                                                onClick={() =>
                                                  this.itemData(
                                                    item,
                                                    this.state.shipmentData[
                                                      indexShipment
                                                    ]
                                                  )
                                                }
                                              >
                                                <img
                                                  style={{ margin: "-4px" }}
                                                  src={
                                                    darkMode
                                                      ? plusDarkIcon
                                                      : plusIcon
                                                  }
                                                  alt="exp"
                                                />
                                              </Button>
                                              <span
                                                className="tooltipIcontext"
                                                style={{
                                                  width: "115px",
                                                  left: "-8px",
                                                }}
                                              >
                                                Add Damage and shortage
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card>
                                    </Col>
                                  </Row>
                                );
                              }
                            )}
                          </Card>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </>
              );
            })
          ) : (
            <Card
              className="darkBgQuickFilter"
              style={{
                height: "600px",
              }}
            >
              <div>
                <span
                  style={{
                    position: "relative",
                    top: "300px",
                  }}
                  className="d-flex justify-content-center fontRoboto154 light__span"
                >
                  No Shipment Available{" "}
                </span>
              </div>
            </Card>
          )}
          {this.state.shipmentData?.length > 0 ? (
            <>
              <div
                className="float-bottom"
                style={{
                  // paddingTop: "600px",
                  position: "fixed",
                  bottom: "0",
                  display: "flex",
                  marginLeft: "1.5rem",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingBottom: "9px",
                }}
              >
                <div>
                  <Button
                    style={{
                      width: "150px",
                      margin: "0.5rem",
                    }}
                    color="primary"
                    className="darkAgreementAddToList button-wide buttonClrS "
                    onClick={() => this.handleSubmitTrip()}
                  >
                    {epodFlag ? "Trip Complete" : "EPOD Complete"}
                  </Button>
                  <Button
                    type="button"
                    // color="secondary"
                    style={{
                      width: "150px",
                      margin: "0.5rem",
                    }}
                    className="small align-middle mb-1 rounded-black  buttomClr"
                    onClick={() => this.EPODModalToggle()}
                  >
                    Acknowledge LR
                  </Button>
                </div>
                <div
                  style={{ position: "fixed", right: "0", marginRight: "12px" }}
                >
                  <div className="float-right">
                    <div className=" d-flex flex-row">
                      <div>
                        <span
                          className="fontRoboto15 light__span pr-2"
                          style={{
                            position: "relative",
                            top: "10px",
                          }}
                        >
                          Add UnLoading Charges
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            style={{
                              height: "30px",
                              backgroundColor: darkMode ? "#2e2e46" : "",
                              border: darkMode ? "1px solid #6e6969" : "",
                            }}
                            type="number"
                            className=" mt-2 CardInputFields darkTexBox dark__span"
                            value={this.state.unloadingCharge}
                            onChange={(e) => {
                              this.handleChangeCharge(e);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Col xs={12}>
                  <Card className="addAgreementCardBottom indentExpiry">
                    <Row
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px !important",
                      }}
                    >
                      <Col xs={4} md={7}>
                        <div className="d-flex ml-4">
                          <div className="m-2">
                            <Button
                              style={{
                                width: "150px",
                              }}
                              color="primary"
                              className="darkAgreementAddToList button-wide buttonClrS "
                              onClick={() => this.handleSubmitTrip()}
                            >
                              {epodFlag ? "Trip Complete" : "EPOD Complete"}
                            </Button>
                          </div>
                          <div
                            className="m-2"
                            style={
                              {
                                width: "151px",
                              }
                            }
                          >
                            <Button
                              type="button"
                              style={{
                                width: "150px",
                              }}
                              // color="secondary"
                              className="small align-middle mb-1 rounded-black  buttomClr"
                              onClick={() => this.EPODModalToggle()}
                            >
                              Acknowledge LR
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} md={4}>
                        <div className="float-right">
                          <div className=" d-flex flex-row">
                            <div>
                              <span
                                className="fontRoboto15 light__span pr-2"
                                style={{
                                  position: "relative",
                                  top: "10px",
                                }}
                              >
                                Add UnLoading Charges
                              </span>
                            </div>
                            <div>
                              <span>
                                <input
                                  style={{
                                    height: "30px",
                                    backgroundColor: darkMode ? "#2e2e46" : "",
                                    border: darkMode ? "1px solid #6e6969" : "",
                                  }}
                                  type="number"
                                  className=" mt-2 CardInputFields darkTexBox dark__span"
                                  value={this.state.unloadingCharge}
                                  onChange={(e) => {
                                    this.handleChangeCharge(e);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    
                    </Row>
                  </Card>
                </Col> */}
              </div>
            </>
          ) : (
            ""
          )}
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    ...state,
    trips: state.Trip.trips.trips,
    fetchId: state.Trip.trips.fetchId,
    vehicleType: state.VehicleType.vehicleType,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    shipmentItemDataStore: state?.Trip?.trips?.shipmentPayloadData,
  };
};

export default connect(mapStateToProps, {
  fetchSignedURL_LRFront,
  fetchShipmentTripById,
  tripEpod,
  storeShipmentData,
})(EPODIndex);
