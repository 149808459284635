import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { fetchTripById } from "../../store/trip/actions";
import { trackLocationTripById } from "../../store/trip/actions";
import formatDate from "../DateFormatter";
import { timeDiff } from "../../components/Common/utils";
import TripInfo from "./TripInfo";
import TripTransitionInfo from "./TripTransitionInfo";
import {
  DateIcon,
  QualityCheck,
  Completed,
  QualityCheckTick,
  ArrivedAtSource
} from "../../components/Common/CommonIcons";
import { toPascalCase } from "../../components/Common/utils";
import { getLocalStorage } from "./RowHeaders/RowHeaders";
import "./tripDetails.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import QualityCheckModel from "./QualityCheckModel";
import TripDetailShipmentModel from "./ShipmentModel";
import TripHistoryModel from "./TripHistoryModel";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripDetails_newUI extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      tripTransitionLogs: [],

      showTripDetailModel: false,
      showShipDetailModel: false,
      showTripHistoryModel: false,
      tripDetailId: " ",
      lrStatus: " ",
      driverName: " ",
      transporterName: " ",
      tripIdHistory: " ",
      statusArray: [],
      currentMenu: "tripInfo",
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.props.fetchTripById(
      this.props.history.location.state?.tripDetails._id,
      (res) => {
        this.setState(
          {
            tripTransitionLogs: res.data[0].tripStatusTransitionLogs,
            loading: false,
          },
          () => {
            this.organizer();
          }
        );
      }
    );
  };
  selectMenu = (menu) => {
    this.setState({ currentMenu: menu });
  };
  organizer = () => {
    const statusMap = {
      arrived_at_source: "Arrived At Source",
      loading_in_progress: "Loading In Progress",
      quality_check_completed: "Quality Check Completed",
      loading_completed: "In Transit",
      epod_completed: "Completed",
      shipment_verified: "Shipment Verified",
      epod_in_progress: "Epod in Progress",
      arrived_at_via: "Arrived at via",
      cancelled: "Cancelled",
      // Add more conversions as needed
    };
    // Define the class names based on the status name
    const classNameMap = {
      "Arrived At Source": "frist1",
      "Loading In Progress": "second1",
      "Quality Check Completed": "sixth1",
      "In Transit": "third1",
      Completed: "fourth1",
      "Shipment Verified": "seventh1",
      "Epod in Progress": "third1",
      "Arrived at via": "eighth1",
    };
    const iconNameMap = {
      "Arrived At Source": <ArrivedAtSource/>,
      "Loading In Progress": <DateIcon color={"white"} />,
      "Quality Check Completed": <QualityCheck />,
      "In Transit":  <ArrivedAtSource/>,
      Completed: <Completed />,
      "Shipment Verified": <DateIcon color={"white"} />,
      "Epod in Progress": <DateIcon color={"white"} />,
      "Arrived at via": <DateIcon color={"white"} />,
      Cancelled: <DateIcon color={"white"} />,
    };
    const miniIconNameMap = {
      "Arrived At Source": "",
      "Loading In Progress": "",
      "Quality Check Completed": <QualityCheckTick/>,
      "In Transit": "",
      "Completed": "",
      "Shipment Verified": <QualityCheckTick/>,
      "Epod in Progress": "",
      "Arrived at via":"",
      "Cancelled": "",
    };
    const statusArray = [];
    this.state.tripTransitionLogs.forEach((ele, index) => {
      const formattedStatus = statusMap[ele.curStatus] || ele.curStatus;
      const className = classNameMap[formattedStatus] || "";
      const iconName = iconNameMap[formattedStatus] || "";
      const miniIconName = miniIconNameMap[formattedStatus] || "";
      statusArray.push({
        index: index,
        curStatus: formattedStatus,
        curData: ele,
        className,
        iconName,
        miniIconName
      });
    });

    this.setState({ statusArray: statusArray });
  };

  driverPopulate = (value) => {
    let name;
    let phone;
    if (value.name !== "") {
      name = value.name;
    } else name = "----";
    if (value.phone !== "") phone = value.phone;
    else phone = "----";
    if (name === "----" && phone === "----") return "-----";
    else return name + " (" + phone + ")";
  };

  tripDetailToggleModel = async (id) => {
    this.setState({ showTripDetailModel: !this.state.showTripDetailModel });
    this.setState({ tripDetailId: id });
  };

  tripShipmentToggleModel = async (id, propsLr) => {
    this.setState({ showShipDetailModel: !this.state.showShipDetailModel });
    this.setState({ tripDetailId: id, lrStatus: propsLr });
  };

  tripHistoryToggleModel = async (id, name, driver, tripIdHistory) => {
    this.setState({ showTripHistoryModel: !this.state.showTripHistoryModel });
    this.setState({
      tripDetailId: id,
      transporterName: name,
      driverName: driver,
      tripIdHistory: tripIdHistory,
    });
  };

  route = (via) => {
    return via?.map((e, i) => (
      <div key={i}>
        {i ? (
          <div className="text-left pr-4" style={{ fontSize: "0.6em" }}>
            <i
              style={{ color: "#5664D2" }}
              className="fas fa-ellipsis-v ml-1"
            ></i>
          </div>
        ) : null}

        <div className="text-left d-flex align-items-center flex-nowrap">
          <i
            style={{ color: "#5664D2" }}
            className="fas fa-circle iconSize"
          ></i>
          <span className="ml-2">{e.label}</span>
        </div>
      </div>
    ));
  };
  shareLink = (shortLink1, type) => {
    navigator.clipboard.writeText(shortLink1);
    NotificationManager.success(`${shortLink1} Is  Copied`, "Success");
  };
  formatDateLine = (date) => {
    return (
      <span>
        {moment(date).format("MMM-DD-YYYY")} ,{moment(date).format("h:mm A")}
      </span>
    );
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;

    let tripDetailArray = [];
    let tripDetails = this.props.history.location.state?.tripDetails;
    tripDetailArray.push(tripDetails);
    let data = [];
    tripDetailArray.forEach((ele) => {
      data.push({
        id: ele?._id,
        tripId: ele?.tripId ? ele?.tripId : "N/A",
        indentId: ele?.indentId ? ele?.indentId?.indentId : null,
        driver: ele?.driver ? this.driverPopulate(ele.driver) : "N/A",
        source: ele?.source ? `${ele?.source?.label}` : "N/A",
        destination: ele?.destination ? `${ele.destination.label}` : "N/A",
        vehicle:
          (
            <>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele?.vehicle?.regNo}
              </p>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele?.vehicle?.type?.label}
              </p>
            </>
          ) || "N/A",
        transporter: ele?.transporter?.name || "N/A",
        route: ele?.via?.length ? <>{this.route(ele.via)}</> : "DIRECT",
        description: ele.description,
        startTime: ele?.startTime ? formatDate(ele.startTime) : "N/A",
        expiryTime: ele?.expiryTime ? formatDate(ele.expiryTime) : "N/A",
        // status: this.status(ele.status, ele.lastRejection, ele._id) || "---",
        subStatus: ele?.subStatus,
      });
    });
    const modalStyle = {
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
      marginRight: "0vw",
      height: "724px",
      overflowY: "scroll",
      paddingBottom: "22px",
    };
    return (
      <React.Fragment>
        {getLocalStorage()}
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xl="12" className="p-1">
              <div>
                <section class="column-container">
                  <div className="col-1-1 pad">
                    <>
                    <TripTransitionInfo
                        tripDetails={tripDetails}
                        data={data}
                        statusArray={this.state.statusArray}
                        modalStyle={modalStyle}
                        tripDetailToggleModel={this.tripDetailToggleModel}
                        tripHistoryToggleModel={this.tripHistoryToggleModel}
                        tripShipmentToggleModel={this.tripShipmentToggleModel}
                        toPascalCase={toPascalCase}
                        timeDiff={timeDiff}
                        tripTransitionLogs={this.state.tripTransitionLogs}
                    />
                    </>
                  </div>

                  <div className="col-full pad color3">
                    <TripInfo
                      tripDetails={tripDetails}
                      selectMenu={this.selectMenu}
                      currentMenu={this.state.currentMenu}
                      driverPopulate={this.driverPopulate}
                      route={this.route}
                      shareLink={this.shareLink}
                      formatDateLine={this.formatDateLine}
                    />
                  </div>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
        <QualityCheckModel
          isOpen={this.state.showTripDetailModel}
          toggleModel={this.tripDetailToggleModel}
          tripIds={this.state.tripDetailId}
        />

        <TripDetailShipmentModel
          isOpen={this.state.showShipDetailModel}
          toggleModel={this.tripShipmentToggleModel}
          tripIds={this.state.tripDetailId}
          lrStatus={this.state.lrStatus}
        />

        <TripHistoryModel
          isOpen={this.state.showTripHistoryModel}
          toggleModel={this.tripHistoryToggleModel}
          tripIds={this.state.tripDetailId}
          transporter={this.state.transporterName}
          driver={this.state.driverName}
          tripIdHistory={this.state.tripIdHistory}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationTripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
})(TripDetails_newUI);
