import * as React from "react";

export const SecondaryPolyline = (options) => {
  const [Polyline, setPolyline] = React.useState();

  React.useEffect(() => {
    if (!Polyline) {
      setPolyline(
        new window.google.maps.Polyline({
          strokeOpacity: 2.0,
          strokeWeight: 3,
          geodesic: true,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              },
              offset: "100%",
              repeat: "80px",
            },
          ],
        })
      );
    }

    // remove marker from map on unmount
    return () => {
      if (Polyline) {
        Polyline.setMap(null);
      }
    };
  }, [Polyline]);
  React.useEffect(() => {
    if (Polyline) {
      Polyline.setOptions(options);
    }
  }, [Polyline, options]);
  return null;
};
