import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  STORE_SHIPMENTDATA,
  types,
} from "./actionTypes";
export const storeShipmentData = (payload) =>({
  type: STORE_SHIPMENTDATA,
  payload
})
export const tripQualityCheck = (values,callback)=>({
  type: types.TRIP_QUALITY_CHECK,
  values,
  callback
})
export const fetchSIgnedUrlQualityCheck = (id,callback) => ({
  type: types.SPEC_FETCHSIGNEDURL_QUALITYCHECK,
  id,
  callback,
});
export const fetchTrips = (offset, filters, hopsData, callback) => ({
  type: types.FETCH_TRIPS,
  offset,
  filters,
  hopsData,
  callback,
});
export const fetchTripsTower = (offset, filters, callback) => ({
  type: types.FETCH_TRIP_TOWER,
  offset,
  filters,
  callback,
});
export const fetchTripsExcel = (offset, filters, callback) => ({
  type: types.FETCH_TRIPS_EXCEL,
  offset,
  filters,
  callback,
});

export const fetchTransporterTrips = (
  offset,
  filters,
  searchKey,
  searchValue
) => ({
  type: types.FETCH_TRANSPORTER_TRIPS,
  offset,
  filters,
  searchKey,
  searchValue,
});
export const fetchTripsfilter = (offset, filters, callback) => ({
  type: types.FETCH_FILTER_TRIPS,
  offset,
  filters,
  callback,
});
export const fetchHops = (callback) => ({
  type: types.FETCH_HOPS_LIST,
  callback,
});
export const fetchTripById = (id, callback) => ({
  type: types.FETCH_TRIP_BYID,
  id,
  callback,
});
export const fetchShipmentTripById = (id, callback) => ({
  type: types.FETCH_SHIPMENT_TRIP_BYID,
  id,
  callback,
});
export const fetchTripBillsById = (id) => ({
  type: types.FETCH_TRIPS_BILLS,
  id,
});
export const startLoading = (tripID, callback) => ({
  type: types.START_LOADING,
  tripID,
  callback,
});
export const finishLoading = (tripID, callback) => ({
  type: types.FINISH_LOADING,
  tripID,
  callback,
});
export const trackLocationTripById = (id, callback) => ({
  type: types.TRACK_LOCATION_BYTRIPID,
  id,
  callback,
});
export const trackTripById = (id, callback) => ({
  type: types.TRACK_TRIP_BYID,
  id,
  callback,
});

export const shipmentVerify = (verify, callback) => ({
  type: types.SHIPMENT_VERIFY,
  verify,
  callback,
});
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createTrip = (values, callback) => ({
  type: types.CREATE_TRIP,
  values,
  callback,
});
export const editTrip = (values, callback) => ({
  type: types.EDIT_TRIP,
  values,
  callback,
});
export const changeTripStatus = (values, callback) => ({
  type: types.CHANGE_TRIP_STATUS,
  values,
  callback,
});
export const deleteTrip = (id, callback) => ({
  type: types.DELETE_TRIP,
  id,
  callback,
});
export const createReportIncident = (values, callback) => ({
  type: types.CREATE_REPORTINCIDENT,
  values,
  callback,
});

export const updateDriver = (values, callback) => ({
  type: types.UPDATE_DRIVER,
  values,
  callback,
});
export const fetchLocationById = (id, callback) => ({
  type: types.FETCH_LOCATION_BY_ID,
  id,
  callback,
});

export const cancelTrip = (values, callback) => ({
  type: types.CANCEL_TRIP,
  values,
  callback,
});

export const updateTrip = (values, callback) => ({
  type: types.UPDATE_TRIP_DETAILS,
  values,
  callback,
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width, layoutType) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

export const changeSidebarTheme = (theme, layoutType) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: { theme, layoutType },
});

export const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});
export const change_arrivedAtSource_status = (tripID, callback) => ({
  type: types.CHANGE_ARRIVEDATSOURCE_STATUS,
  tripID,
  callback,
});
export const skipViaPoint = (value, callback) => ({
  type: types.SKIP_VIA_POINT,
  value,
  callback,
});


export const startUnloading = (tripID, callback) => ({
  type: types.START_UNLOADING,
  tripID,
  callback,
});

export const fetchSignedURL_LRFront = (tripId, callback) => ({
  type: types.FETCH_SIGNED_URL_LR_FRONT,
  tripId,
  callback,
});
export const fetchSignedURL_LRBack = (tripId, callback) => ({
  type: types.FETCH_SIGNED_URL_LR_BACK,
  tripId,
  callback,
});
export const fetchSignedURL_UL_Receipts = (tripId, callback) => ({
  type: types.FETCH_SIGNED_URL_UNLOAD_RECEIPTS,
  tripId,
  callback,
});
export const fetchSignedURL_ShipmentDamaged = (shipmentID,ItemID, callback) => ({
  type: types.FETCH_SIGNED_URL_SHIPMENT_DAMAGED,
  shipmentID,
  ItemID,
  callback,
});
export const fetchSignedURL_ShimentShortage = (shipmentID,ItemID, callback) => ({
  type: types.FETCH_SIGNED_URL_SHIMENT_SHORTAGE,
  shipmentID,
  ItemID,
  callback,
});
export const tripEpod= (values,callback)=>({
  type: types.TRIP_EPOD,
  values,
  callback
})
