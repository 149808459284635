import { types } from "./actionTypes";

export const fetchSourceSecondary = (callback) => ({
  type: types.FETCH_SOURCE_SECONDARY,
  callback,
});

export const setCustomerDropDown = (index) => ({
  type: types.SET_CUSTOMER_DROPDOWN,
  index,
});

export const setCustomerDropDownFalse = () => ({
  type: types.SET_CUSTOMER_DROPDOWN_FALSE,
});

export const setAssignedCustomerDropDown = (index) => ({
  type: types.SET_ASSIGNED_CUSTOMER_DROPDOWN,
  index,
});

export const setAssignedCustomerDropDownFalse = () => ({
  type: types.SET_ASSIGNED_CUSTOMER_DROPDOWN_FALSE,
});

export const fetchVehicleTypeSecondary = (data) => ({
  type: types.FETCH_VEHICLES_TYPE_SECONDARY,
  ...data,
});

export const fetchTransporterSecondary = (source, vehicleType, callback) => ({
  type: types.FETCH_TRANSPORTER_SECONDARY,
  source,
  vehicleType,
  callback,
});

export const fetchCustomersSecondary = (
  source,
  transporter,
  freightCode,
  vehicleType,
  callback
) => ({
  type: types.FETCH_CUSTOMERS_SECONDARY,
  source,
  transporter,
  freightCode,
  vehicleType,
  callback,
});

export const createIndentSecondary = (values, callback) => ({
  type: types.CREATE_INDENT_SECONDARY,
  values,
  callback,
});

export const costDisplayFormSecondary = (values, callback) => ({
  type: types.COST_DISPLAY_FORM_SECONDARY,
  values,
  callback,
});

export const fetchSecondaryIndents = (offset, filters, callback) => ({
  type: types.FETCH_INDENTS_SECONDARY,
  offset,
  filters,
  callback,
});

export const fetchCustomerOptionsSecondary = (callback) => ({
  type: types.FETCH_CUSTOMERS_OPTIONS_SECONDARY,
  callback,
});

export const fetchVehicletypeOptionsSecondary = (callback) => ({
  type: types.FETCH_VEHICLETYPE_OPTIONS_SECONDARY,
  callback,
});

export const fetchTransporterOptionsSecondary = (callback) => ({
  type: types.FETCH_TRANSPORTER_OPTIONS_SECONDARY,
  callback,
});

export const fetchSecondaryAssignedIndents = (offset, filters, callback) => ({
  type: types.FETCH_ASSIGNED_INDENTS_SECONDARY,
  offset,
  filters,
  callback,
});

export const SecondaryIndentStatusAccept = ({ value, callback }) => ({
  type: types.SECONDARY_INDENT_STATUS_ACCEPT,
  value,
  callback,
});

export const SecondaryIndentStatusReject = ({ values, callback }) => ({
  type: types.SECONDARY_INDENT_STATUS_REJECT,
  values,
  callback,
});

export const freightsMasterSecondary = (filters, searchFilter, callback) => ({
  type: types.FREIGHTS_MASTER_SECONDARY,
  filters,
  searchFilter,
  callback,
});

export const fetchCustomerMasterSecondary = (
  filters,
  searchFilter,
  callback
) => ({
  type: types.FETCH_CUSTOMERS_MASTER_SECONDARY,
  filters,
  searchFilter,
  callback,
});

export const setCustomerDropDownMaster = (index) => ({
  type: types.SET_CUSTOMER_DROPDOWN_MASTER,
  index,
});

export const FetchVehiclePlacementDetailReportsSecondary = (
  values,
  callback
) => ({
  type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_SECONDARY,
  values,
  callback,
});

export const FetchVehiclePlacementDetailReportsForExcelSecondary = (
  values,
  callback
) => ({
  type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SECONDARY,
  values,
  callback,
});

export const cancelTripSecondary = (values, callback) => ({
  type: types.CANCEL_TRIP_SECONDARY,
  values,
  callback,
});
