let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.masters?.ROLES,
        field: "name",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.DESCRIPTION,
        field: "description",
        sort: "asc",
        width: 4,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}

export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.ROLES,
      field: "name",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.DESCRIPTION,
      field: "description",
      sort: "asc",
      width: 4,
    },
  ],
};

export const searchBar = {
  searchBarData: [
    {
      label: userLang?.masters?.ROLES,
      field: "roles",
      type: "select",
    },
    {
      label: userLang?.masters?.DESCRIPTION,
      field: "description",
      type: "text",
    },
    {},
  ],
};
