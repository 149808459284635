import React, { Component } from "react";
import { Card, Row, Col, CardBody, Button } from "reactstrap";
import { StatusIndicator } from "./StatusIndicator";
import moment from "moment";
import { DateIcon } from "../../components/Common/CommonIcons";
import { withRouter } from "react-router-dom";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TripTransitionInfo extends Component {
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    const {
      tripDetails,
      data,
      statusArray,
      modalStyle,
      tripDetailToggleModel,
      tripHistoryToggleModel,
      tripShipmentToggleModel,
      toPascalCase,
      tripTransitionLogs,
      timeDiff,
    } = this.props;
    return (
      <div>
        <Card>
          <CardBody style={modalStyle} cardHeightMedia>
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="dark__span">Tracking Details</h4>
              </div>
              <div>
                <Row>
                  <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
                    <Button
                      style={{
                        width: "60px",
                        height: "36px",
                        backgroundColor: "#3e3e3e",
                      }}
                      color="secondary"
                      size="sm"
                      onClick={() =>
                        this.props.history.push({
                          pathname: "/trips",
                        })
                      }
                    >
                      <i className="fas fa-arrow-circle-left fa-2x"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>

            <Card className="darkCardStyle cardStyle mt-2">
              <CardBody>
                <div className="online mr-2 ">
                  <div className="online-indicator">
                    <span className="blink"></span>
                  </div>
                  <p className="online-text light__span">
                    {" "}
                    Current Status :{" "}
                    {tripDetails?.subStatus ? (
                      <StatusIndicator status={tripDetails?.subStatus} />
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
              </CardBody>
            </Card>

            <hr></hr>
            <div className="tripTrackParent-element">
              <div className="mr-4">
                <div className="track-element">
                  <DateIcon color={"white"} />
                </div>

                {statusArray?.length ? (
                  <>
                    <div className="track-elementP"></div>
                    <div className="track-element2"></div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "100%" }}>
                <Card className="darkCardStyle cardStyle">
                  <CardBody>
                    <div className="fontRoboto618 dark__span">
                      {userLang?.dashboard?.PLANNED}
                    </div>
                    <div className="fontRoboto1541 mt-2 light__span">
                      {tripDetails.source ? tripDetails.source?.label : "N/A"}
                    </div>
                    <div className="fontRoboto17 light__span mt-3">
                      {tripDetails.createdAt
                        ? moment(tripDetails.createdAt).format(
                            "MMM-DD-YYYY ,  HH:mm A"
                          )
                        : "N/A"}
                    </div>
                  </CardBody>
                </Card>
                {statusArray?.length ? (
                  <>
                    <div className="d-flex align-items-center">
                      <i
                        className="fa fa-clock mr-3 mt-3"
                        style={{
                          color: "darkcyan",
                          fontSize: "19px",
                        }}
                      ></i>
                      <div className="fontRoboto1541 light__span mt-3">
                        {tripDetails &&
                          tripTransitionLogs &&
                          timeDiff(
                            tripDetails?.createdAt,
                            tripTransitionLogs[0]?.updatedAt
                          )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* ,,,,,,,,,,,,,,,,,,,,,,,,status array.....................*/}
            {statusArray.map((ele, index, array) => (
              <div className="tripTrackParent-element">
                <div className="mr-4">
                  <div
                    className={`track-element ${
                      ele?.curStatus === "Completed" ? "track-element" : ""
                    }`}
                  >
                    {ele?.iconName}
                  </div>
                  {index !== array.length - 1 ? (
                    <>
                      <div className="track-element1"></div>
                      <div className="track-element2"></div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ width: "100%" }}>
                  <Card className="darkCardStyle cardStyle">
                    <CardBody>
                      <div className="fontRoboto618 dark__span">
                        {ele?.curStatus} &nbsp;{ele?.miniIconName}
                      </div>
                      <div className="fontRoboto1541  light__span mt-2">
                        {ele?.curData?.hop ? ele?.curData?.hop?.label : "N/A"}
                      </div>

                      <div className="fontRoboto1541   light__span  mt-1">
                        {ele?.curData?.updatedBy?.name
                          ? toPascalCase(ele?.curData?.updatedBy?.name)
                          : "N/A"}
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="fontRoboto17  light__span  mt-3">
                          {ele?.curData?.updatedAt
                            ? moment(ele?.curData?.updatedAt).format(
                                "MMM-DD-YYYY ,  HH:mm A"
                              )
                            : "N/A"}
                        </div>
                        {ele?.curStatus === "Quality Check Completed" ||
                        ele?.curStatus === "In Transit" ||
                        ele?.curStatus === "Shipment Verified" ||
                        ele?.curStatus === "Completed" ? (
                          <div
                            className="fontRoboto17 mt-3  menu-span"
                            onClick={() => {
                              return ele?.curStatus ===
                                "Quality Check Completed"
                                ? tripDetailToggleModel(data[0]?.id)
                                : ele?.curStatus === "Shipment Verified"
                                ? tripShipmentToggleModel(data[0]?.id, {
                                    lrCopy: false,
                                  })
                                : ele?.curStatus === "In Transit"
                                ? tripHistoryToggleModel(
                                    data[0]?.id,
                                    data[0]?.transporter,
                                    data[0]?.driver,
                                    data[0]?.tripId
                                  )
                                : ele?.curStatus === "Completed"
                                ? tripShipmentToggleModel(data[0]?.id, {
                                    lrCopy: true,
                                  })
                                : null;
                            }}
                          >
                            View Detail
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </CardBody>
                  </Card>

                  {index !== array.length - 1 ? (
                    <div className="d-flex align-items-center">
                      <i
                        className="fa fa-clock mr-3 mt-3"
                        style={{
                          color: "darkcyan",
                          fontSize: "19px",
                        }}
                      ></i>
                      <div className="fontRoboto1541 mt-3">
                        {timeDiff(
                          array[index]?.curData?.updatedAt,
                          array[index + 1]?.curData?.updatedAt
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default withRouter(TripTransitionInfo);
